import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import {
  HOME,
  ALARM,
  SETTING,
  ANNOUNCEMENTS,
  FAQ as FAQRoute,
  LOGIN,
  FIND_ID,
  FIND_PW,
  SIGNUP_CHECK,
  SIGNUP,
  MYPAGE,
  MY_INFO,
  COVERAGE_DETAIL,
  CLAIM_HISTORY,
  INQUIRY_HISTORY,
  ACCOUNT_DELETION,
  INQUIRY_REGISTER,
  INQUIRY_DETAIL,
  GROUP_ACCIDENT_INSURANCE,
  CLAIM_ACCIDENT_STEP1,
  CLAIM_ACCIDENT_STEP2,
  CLAIM_ACCIDENT_STEP3,
  CLAIM_ACCIDENT_STEP4,
  CLAIM_ACCIDENT_STEP5,
  REQUIRED_DOC_INFO,
  LONG_TRAVEL_INSURANCE,
  LONG_TRAVEL_INSURANCE_STEP1,
  LONG_TRAVEL_INSURANCE_STEP2,
  LONG_TRAVEL_INSURANCE_STEP3,
  LONG_TRAVEL_INSURANCE_STEP4,
  OVERSEAS_TRAVEL_INSURANCE,
  OVERSEAS_TRAVEL_INSURANCE_STEP1,
  OVERSEAS_TRAVEL_INSURANCE_STEP2,
  OVERSEAS_TRAVEL_INSURANCE_STEP3,
  OVERSEAS_TRAVEL_INSURANCE_STEP4,
  DOMESTIC_TRAVEL_INSURANCE,
  DOMESTIC_TRAVEL_INSURANCE_STEP1,
  DOMESTIC_TRAVEL_INSURANCE_STEP2,
  DOMESTIC_TRAVEL_INSURANCE_STEP3,
  DOMESTIC_TRAVEL_INSURANCE_STEP4,
  LONG_TRAVEL_INSURANCE_DETAIL,
  OVERSEAS_TRAVEL_INSURANCE_DETAIL,
  DOMESTIC_TRAVEL_INSURANCE_DETAIL,
  TERMS_APPLICATION,
  TERMS_FINANCE,
  TERMS_PERSONAL_INFO,
  TERMS_IDENTIFY_INFO,
  SUBMISSION_SURVICE,
  VERIFY_CALLBACK,
  IDENTITY_VERIFICATION,
  CHANGE_PW,
  PET,
  PET_HIPET_PRODUCT,
  PET_HIPET_DETAIL,
  PET_HIPET_NOTE,
  PET_HIPET_REGISTER,
  PET_GOODANDGOOD_PRODUCT,
  PET_GOODANDGOOD_DETAIL,
  PET_GOODANDGOOD_NOTE,
  PET_GOODANDGOOD_REGISTER,
} from './utils/routers';
import Announcements from './pages/servies/Announcements';
import FAQ from './pages/servies/FAQ';
import Home from './pages/main/Home';
import Alarm from './pages/main/Alarm';
import Setting from './pages/main/Setting';
import Login from './pages/account/Login';
import FindId from './pages/account/FindId';
import FindPw from './pages/account/FindPw';
import SignupCheck from './pages/account/SignupCheck';
import Signup from './pages/account/Signup';
import Mypage from './pages/mypage/Mypage';
import MyInfo from './pages/mypage/MyInfo';
import CoverageDetails from './pages/mypage/CoverageDetails';
import ClaimHistory from './pages/mypage/ClaimHistory';
import InquiryHistory from './pages/mypage/InquiryHistory';
import AccountDeletion from './pages/mypage/AccountDeletion';
import ClaimHistoryDetail from './pages/mypage/ClaimHistoryDetail';
import InquiryRegister from './pages/mypage/InquiryRegister';
import InquiryDetail from './pages/mypage/InquiryDetail';
import AnnouncementsDetail from './pages/servies/AnnouncementsDetail';
import GroupAccidentInsurance from './pages/groupAccidentInsurance/GroupAccidentInsurance';
import ClaimAccientStep1 from './pages/groupAccidentInsurance/ClaimAccientStep1';
import ClaimAccientStep2 from './pages/groupAccidentInsurance/ClaimAccientStep2';
import ClaimAccientStep3 from './pages/groupAccidentInsurance/ClaimAccientStep3';
import ClaimAccientStep4 from './pages/groupAccidentInsurance/ClaimAccientStep4';
import ClaimAccientStep5 from './pages/groupAccidentInsurance/ClaimAccientStep5';
import RequiredDocInfo from './pages/requiredDocInfo/RequiredDocInfo';
import RequiredDocInfoDetail from './pages/requiredDocInfo/RequiredDocInfoDetail';
import LongTravelInsurance from './pages/longTravelInsurance/LongTravelInsurance';
import LongTravelInsuranceStep1 from './pages/longTravelInsurance/LongTravelInsuranceStep1';
import LongTravelInsuranceStep2 from './pages/longTravelInsurance/LongTravelInsuranceStep2';
import LongTravelInsuranceStep3 from './pages/longTravelInsurance/LongTravelInsuranceStep3';
import LongTravelInsuranceStep4 from './pages/longTravelInsurance/LongTravelInsuranceStep4';
import OverseasTravelInsurance from './pages/overseasTravelInsurance/OverseasTravelInsurance';
import OverseasTravelInsuranceStep1 from './pages/overseasTravelInsurance/OverseasTravelInsuranceStep1';
import OverseasTravelInsuranceStep2 from './pages/overseasTravelInsurance/OverseasTravelInsuranceStep2';
import OverseasTravelInsuranceStep3 from './pages/overseasTravelInsurance/OverseasTravelInsuranceStep3';
import OverseasTravelInsuranceStep4 from './pages/overseasTravelInsurance/OverseasTravelInsuranceStep4';
import DomesticTravelInsurance from './pages/domesticTravelInsurance/DomesticTravelInsurance';
import DomesticTravelInsuranceStep1 from './pages/domesticTravelInsurance/DomesticTravelInsuranceStep1';
import DomesticTravelInsuranceStep2 from './pages/domesticTravelInsurance/DomesticTravelInsuranceStep2';
import DomesticTravelInsuranceStep3 from './pages/domesticTravelInsurance/DomesticTravelInsuranceStep3';
import DomesticTravelInsuranceStep4 from './pages/domesticTravelInsurance/DomesticTravelInsuranceStep4';
import LongTravelInsuranceDetail from './pages/longTravelInsurance/LongTravelInsuranceDetail';
import OverseasTravelInsuranceDetail from './pages/overseasTravelInsurance/OverseasTravelInsuranceDetail';
import DomesticTravelInsuranceDetail from './pages/domesticTravelInsurance/DomesticTravelInsuranceDetail';
import Application from './pages/terms/Application';
import Finance from './pages/terms/Finance';
import PersonalInfo from './pages/terms/PersonalInfo';
import IdentifyInfo from './pages/terms/IdentifyInfo';
import SubmissionService from './pages/terms/SubmissionService';
import IdentityVerification from './pages/api/auth/IdentityVerification';
import CallbackPage from './pages/api/auth/CallbackPage';
import ChangePw from './pages/account/ChangePw';
import Pet from './pages/pet/Pet';
import PetProduct from './pages/pet/PetProduct';
import PetDetail from './pages/pet/PetDetail';
import PetNote from './pages/pet/PetNote';
import PetRegister from './pages/pet/PetRegister';

function Routes() {
  return (
    <Switch>
      {/* 로그인/회원가입 */}
      <Route path={LOGIN} element={<Login />} />
      <Route path={FIND_ID} element={<FindId />} />
      <Route path={FIND_PW} element={<FindPw />} />
      <Route path={CHANGE_PW} element={<ChangePw />} />
      <Route path={SIGNUP_CHECK} element={<SignupCheck />} />
      <Route path={SIGNUP} element={<Signup />} />

      {/* 메인 */}
      <Route path={HOME} element={<Home />} />
      <Route path={ALARM} element={<Alarm />} />
      <Route path={SETTING} element={<Setting />} />

      {/* 설정 > 서비스 */}
      <Route path={ANNOUNCEMENTS} element={<Announcements />} />
      <Route path={`${ANNOUNCEMENTS}/:id`} element={<AnnouncementsDetail />} />
      <Route path={FAQRoute} element={<FAQ />} />

      {/* 마이 */}
      <Route path={MYPAGE} element={<Mypage />} />
      <Route path={MY_INFO} element={<MyInfo />} />
      <Route path={COVERAGE_DETAIL} element={<CoverageDetails />} />
      <Route path={CLAIM_HISTORY} element={<ClaimHistory />} />
      <Route path={`${CLAIM_HISTORY}/:id`} element={<ClaimHistoryDetail />} />
      <Route path={INQUIRY_HISTORY} element={<InquiryHistory />} />
      <Route path={INQUIRY_REGISTER} element={<InquiryRegister />} />
      <Route path={`${INQUIRY_DETAIL}/:id`} element={<InquiryDetail />} />
      <Route path={ACCOUNT_DELETION} element={<AccountDeletion />} />

      {/* 단체 상해 보험 */}
      <Route path={GROUP_ACCIDENT_INSURANCE} element={<GroupAccidentInsurance />} />
      <Route path={CLAIM_ACCIDENT_STEP1} element={<ClaimAccientStep1 />} />
      <Route path={CLAIM_ACCIDENT_STEP2} element={<ClaimAccientStep2 />} />
      <Route path={CLAIM_ACCIDENT_STEP3} element={<ClaimAccientStep3 />} />
      <Route path={CLAIM_ACCIDENT_STEP4} element={<ClaimAccientStep4 />} />
      <Route path={CLAIM_ACCIDENT_STEP5} element={<ClaimAccientStep5 />} />

      {/* 필요 서류 안내 */}
      <Route path={REQUIRED_DOC_INFO} element={<RequiredDocInfo />} />
      <Route path={`${REQUIRED_DOC_INFO}/:id`} element={<RequiredDocInfoDetail />} />

      {/* 해외장기체류보험 */}
      <Route path={LONG_TRAVEL_INSURANCE} element={<LongTravelInsurance />} />
      <Route path={LONG_TRAVEL_INSURANCE_STEP1} element={<LongTravelInsuranceStep1 />} />
      <Route path={LONG_TRAVEL_INSURANCE_STEP2} element={<LongTravelInsuranceStep2 />} />
      <Route path={LONG_TRAVEL_INSURANCE_STEP3} element={<LongTravelInsuranceStep3 />} />
      <Route path={LONG_TRAVEL_INSURANCE_STEP4} element={<LongTravelInsuranceStep4 />} />
      <Route path={LONG_TRAVEL_INSURANCE_DETAIL} element={<LongTravelInsuranceDetail />} />

      {/* 해외여행보험 */}
      <Route path={OVERSEAS_TRAVEL_INSURANCE} element={<OverseasTravelInsurance />} />
      <Route path={OVERSEAS_TRAVEL_INSURANCE_STEP1} element={<OverseasTravelInsuranceStep1 />} />
      <Route path={OVERSEAS_TRAVEL_INSURANCE_STEP2} element={<OverseasTravelInsuranceStep2 />} />
      <Route path={OVERSEAS_TRAVEL_INSURANCE_STEP3} element={<OverseasTravelInsuranceStep3 />} />
      <Route path={OVERSEAS_TRAVEL_INSURANCE_STEP4} element={<OverseasTravelInsuranceStep4 />} />
      <Route path={OVERSEAS_TRAVEL_INSURANCE_DETAIL} element={<OverseasTravelInsuranceDetail />} />

      {/* 국내여행보험 */}
      <Route path={DOMESTIC_TRAVEL_INSURANCE} element={<DomesticTravelInsurance />} />
      <Route path={DOMESTIC_TRAVEL_INSURANCE_STEP1} element={<DomesticTravelInsuranceStep1 />} />
      <Route path={DOMESTIC_TRAVEL_INSURANCE_STEP2} element={<DomesticTravelInsuranceStep2 />} />
      <Route path={DOMESTIC_TRAVEL_INSURANCE_STEP3} element={<DomesticTravelInsuranceStep3 />} />
      <Route path={DOMESTIC_TRAVEL_INSURANCE_STEP4} element={<DomesticTravelInsuranceStep4 />} />
      <Route path={DOMESTIC_TRAVEL_INSURANCE_DETAIL} element={<DomesticTravelInsuranceDetail />} />

      {/* 펫보험 */}
      <Route path={PET} element={<Pet />} />
      <Route path={PET_HIPET_PRODUCT} element={<PetProduct />} />
      <Route path={PET_HIPET_DETAIL} element={<PetDetail />} />
      <Route path={PET_HIPET_NOTE} element={<PetNote />} />
      <Route path={PET_HIPET_REGISTER} element={<PetRegister />} />
      <Route path={PET_GOODANDGOOD_PRODUCT} element={<PetProduct />} />
      <Route path={PET_GOODANDGOOD_DETAIL} element={<PetDetail />} />
      <Route path={PET_GOODANDGOOD_NOTE} element={<PetNote />} />
      <Route path={PET_GOODANDGOOD_REGISTER} element={<PetRegister />} />

      {/* 약관 */}
      <Route path={TERMS_APPLICATION} element={<Application />} />
      <Route path={TERMS_FINANCE} element={<Finance />} />
      <Route path={TERMS_PERSONAL_INFO} element={<PersonalInfo />} />
      <Route path={TERMS_IDENTIFY_INFO} element={<IdentifyInfo />} />
      <Route path={SUBMISSION_SURVICE} element={<SubmissionService />} />

      {/* 본인인증 */}
      <Route path={VERIFY_CALLBACK} element={<CallbackPage />} />
      <Route path={IDENTITY_VERIFICATION} element={<IdentityVerification />} />

      {/* 잘못된 경로 */}
      <Route path='*' element={<Home />} />
    </Switch>
  );
}

export default Routes;
