import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { TDefaultResult, useCustomMutation, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { formatPhoneNumber, splitString } from '../../utils/format';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AlertModal from '../../components/modal/AlertModal';
import LinkModal from '../../components/modal/LinkModal';
import { mypageKeys } from '../../react-query/constants';

const validationSchema = yup.object({
  pw: yup
    .string()
    .trim()
    .test(
      'conditional-password',
      '영문, 숫자, 특수문자 조합 8~16자리로 입력해주세요.',
      function (value) {
        if (!value) return true;
        return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(value);
      }
    ),
  pwConfirm: yup
    .string()
    .test('passwords-match', '비밀번호가 일치하지 않습니다.', function (value) {
      const { pw } = this.parent;
      if (!pw && !value) return true; // 둘 다 비어있는 경우만 통과
      if (!pw && value) return false; // 비밀번호는 비어있는데 확인은 입력된 경우 실패
      return value === pw; // 비밀번호가 입력된 경우 일치 여부 확인
    }),
  email: yup
    .string()
    .trim()
    .email('올바른 이메일을 입력해주세요.')
    .required('이메일을 입력해주세요.'),
});

type TInfoReq = {
  email: string;
  password: string;
};

type TInfo = { signId: string; ssnNumber: string; phone: string; name: string; email: string };
type TInfoRes = TDefaultResult & {
  data: TInfo;
};

function MyInfo() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isShowPw, setIsShowPw] = useState(false);
  const [isShowPwConfirm, setIsShowPwConfirm] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 정보 조회
  const { data, refetch } = useCustomQuery<TInfoRes>(
    mypageKeys.info(),
    endpointConfig.userInfo,
    1,
    {
      retry: 1,
      onError: (error: AxiosError) => console.log(error),
    }
  );

  // 정보 수정
  const mutation = useCustomMutation<TInfoReq>(endpointConfig.userInfo, 'put', 1, {
    onSuccess: (data: TDefaultResult) => {
      if (data.status) {
        setIsSuccess(true);
        refetch();
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      name: '',
      ssnNumber: '',
      signId: '',
      pw: '',
      pwConfirm: '',
      phone: '',
      email: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      // 정보 수정 api 호출
      mutation.mutate({
        email: values.email.trim(),
        password: values.pw.trim(),
      });
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // refetch 후 formik 초기화
  useEffect(() => {
    if (data?.status) {
      const { name, ssnNumber, signId, phone, email } = data.data;
      formik.resetForm({
        values: {
          name: name || '-',
          ssnNumber: ssnNumber || '-',
          signId: signId || '-',
          pw: '',
          pwConfirm: '',
          phone: phone || '-',
          email: email || '',
        },
      });
    }
  }, [data]);

  return (
    <>
      <div className='wrap bg_white'>
        {/* <!-- header --> */}
        <DetailHeader title='정보수정' />
        {/* <!-- // header --> */}
        <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
          {/* <!-- container --> */}
          <div className='container'>
            <div className='guide'>
              <div className='inp_wrap mt_20'>
                <label className='label'>직원명</label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='이름을 입력해 주세요'
                    value={formik.values?.name}
                    readOnly
                  />
                </div>
              </div>
              <div className='inp_wrap mt_16'>
                <label className='label'>주민등록번호</label>
                <div className='dis_flex gap8'>
                  <div className='inp flex1'>
                    <input
                      type='number'
                      placeholder='생년월일 6자리'
                      value={splitString(formik.values?.ssnNumber)[0]}
                      readOnly
                    />
                  </div>
                  <span>-</span>
                  <div className='inp flex1'>
                    <input
                      type='password'
                      placeholder='뒷 번호 7자리'
                      value={splitString(formik.values?.ssnNumber)[1]}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className='inp_wrap mt_16'>
                <label className='label'>아이디</label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='아이디를 입력해 주세요.'
                    value={formik.values?.signId}
                    readOnly
                  />
                </div>
              </div>
              <div
                className={`inp_wrap mt_16 ${formik.errors.pw && formik.touched.pw ? 'state_red' : ''}`}
              >
                <label className='label'>비밀번호 변경</label>
                <div className='inp'>
                  <input
                    type={isShowPw ? 'text' : 'password'}
                    placeholder='새 비밀번호'
                    name='pw'
                    value={formik.values.pw}
                    onChange={formik.handleChange}
                    maxLength={16}
                    onBlur={formik.handleBlur}
                    autoComplete='off'
                  />
                  <button
                    type='button'
                    className={`eye_icon ${isShowPw ? 'active' : ''}`}
                    onClick={() => setIsShowPw(prev => !prev)}
                    style={{
                      display: formik.values.pw ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.pw && formik.touched.pw && (
                  <div className='state_txt'>{formik.errors.pw}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_8 ${
                  formik.touched.pwConfirm && formik.errors.pwConfirm
                    ? 'state_red'
                    : formik.values.pw.trim() && formik.values.pw === formik.values.pwConfirm
                      ? 'state_green'
                      : ''
                }`}
              >
                <div className='inp'>
                  <input
                    type={isShowPwConfirm ? 'text' : 'password'}
                    placeholder='새 비밀번호 확인'
                    name='pwConfirm'
                    value={formik.values.pwConfirm}
                    onChange={formik.handleChange}
                    maxLength={16}
                    onBlur={formik.handleBlur}
                  />
                  <button
                    type='button'
                    className={`eye_icon ${isShowPwConfirm ? 'active' : ''}`}
                    onClick={() => setIsShowPwConfirm(prev => !prev)}
                    style={{
                      display: formik.values.pwConfirm ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.pwConfirm && formik.touched.pwConfirm && (
                  <div className='state_txt'>{'비밀번호가 일치하지 않습니다.'}</div>
                )}
                {formik.values.pw.trim() && formik.values.pw === formik.values.pwConfirm && (
                  <div className='state_txt'>{'비밀번호가 일치합니다.'}</div>
                )}
              </div>
              <div className='inp_wrap mt_16'>
                <label className='label'>휴대폰번호</label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder={formatPhoneNumber(formik.values?.phone)}
                    readOnly
                  />
                </div>
              </div>
              <div
                className={`inp_wrap mt_16 ${formik.errors.email && formik.touched.email ? 'state_red' : ''}`}
              >
                <label className='label'>이메일</label>
                <div className='inp'>
                  <input
                    type='email'
                    placeholder='이메일을 입력해 주세요.'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <button
                    type='button'
                    className='remove_btn'
                    onClick={() => formik.setFieldValue('email', '')}
                    style={{
                      display: formik.values.email ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.email && formik.touched.email && (
                  <div className='state_txt'>{formik.errors.email}</div>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className='foot_container'>
          <div className='foot_btn'>
            <button
              className={`btn blue ${!formik.dirty ? 'disabled' : ''}`}
              type='button'
              onClick={() => formik.handleSubmit()}
            >
              확인
            </button>
          </div>
        </div>
      </div>
      <AlertModal
        title='정보 수정 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
      <LinkModal
        title='정보 수정 완료'
        desc={'정보를 성공적으로 수정하였습니다'}
        onLink={() => setIsSuccess(false)}
        sx={{ display: isSuccess ? 'block' : 'none' }}
        linkTxt='확인'
        hasClose={false}
      />
    </>
  );
}

export default MyInfo;
