import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { HOME } from '../../utils/routers';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { checkLogin } from '../../utils/auth';
import { AxiosError } from 'axios';
import AlarmList from '../../components/alarm/AlarmList';
import EmptyData from '../../components/common/EmptyData';
import { alarmKeys } from '../../react-query/constants';

export type TAlarmHistory = {
  beforeTime: string;
  content: string;
  id: number;
  read_yn: null;
};
export type TAlarmHistoryRes = TDefaultResult & {
  data: {
    result: TAlarmHistory[];
  };
};
function Alarm() {
  const [alarms, setAlarms] = useState<TAlarmHistory[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 알림내역 조회
  const { data } = useCustomQuery<TAlarmHistoryRes>(
    alarmKeys.history(),
    endpointConfig.alarmHistory,
    1,
    {
      enabled: checkLogin(),
      retry: 1,
      onError: (error: AxiosError) => console.log(error),
    }
  );

  useEffect(() => {
    if (data?.status) {
      setAlarms(data.data.result);
    }
  }, [data]);

  return (
    <div className='wrap'>
      {/* header */}
      <DetailHeader title='알림' path={HOME} />

      {/* <!-- container --> */}
      <div className='container gray'>
        <div className='guide' style={{ height: '100%' }}>
          {alarms?.length ? (
            <AlarmList alarms={alarms} />
          ) : (
            <EmptyData desc='아직 등록된 알림이 없습니다' hasIcon={true} />
          )}
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default Alarm;
