import React, { useEffect, useRef } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import { LONG_TRAVEL_INSURANCE_STEP4 } from '../../utils/routers';
import {
  FormControl,
  FormErrorMessage,
  FormInput,
} from '../../components/form-control/formControl';
import useLongTravelFunnel from '../../hooks/useLongTravelFunnel';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LongTravelStep3ValuesType } from '../../types/insurance/long-travel';
import { isNotNumber } from '../../utils/format';
import Postcode from '../../components/DaumPostCode';
import {
  ADDRESS_DETAIL_SCHEMA,
  ADDRESS_SCHEMA,
  EMAIL_SCHEMA,
  ENGLISH_NAME_SCHEMA,
  JOB_SCHEMA,
  NAME_SCHEMA,
  PHONE_NUMBER_SCHEMA,
  PLAN_SCHEMA,
  SCHOOL_NAME_SCHEMA,
  SSN_NUMBER_SCHEMA,
  SUBSCRIPTION_MONTH_SCHEMA,
} from '../../constants/validationSchemas';
import useMaskedLastSsnNumber from '../../hooks/useMaskedLastSsnNumber';
import { setFormikTouched, validFormik } from '../../utils/formik';
import useIsNativeWebView from '../../hooks/useIsNativeWebView';

const longTravelInsuranceStep3Schema = yup.object({
  claimantName: NAME_SCHEMA('피보험자'),
  englishName: ENGLISH_NAME_SCHEMA,
  claimantSsnNumber: SSN_NUMBER_SCHEMA,
  address: ADDRESS_SCHEMA,
  addressDetail: ADDRESS_DETAIL_SCHEMA,
  job: JOB_SCHEMA,
  schoolName: SCHOOL_NAME_SCHEMA,
  phoneNumber: PHONE_NUMBER_SCHEMA,
  plan: PLAN_SCHEMA,
  subscriptionMonth: SUBSCRIPTION_MONTH_SCHEMA,
  contractorName: NAME_SCHEMA('계약자'),
  contractorEnglishName: ENGLISH_NAME_SCHEMA,
  contractorSsnNumber: SSN_NUMBER_SCHEMA,
  email: EMAIL_SCHEMA,
  isSame: yup.boolean(),
});

function LongTravelInsuranceStep3() {
  const { goTo } = useNavigateTo();
  const { step3Values, setStep3Values } = useLongTravelFunnel();
  const isNative = useIsNativeWebView();
  const ref = useRef<HTMLInputElement>(null);
  const formik = useFormik({
    initialValues: step3Values as LongTravelStep3ValuesType,
    validationSchema: longTravelInsuranceStep3Schema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });
  const { maskedLastSsnNumber, onChangeSsnNumber } = useMaskedLastSsnNumber({
    ref,
    formik,
    originName: 'claimantSsnNumber',
  });

  // 두 개의 bottom sheet 참조 생성
  const bottomSheetRef1 = useRef<HTMLDivElement | null>(null);
  const bottomSheetRef2 = useRef<HTMLDivElement | null>(null);

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  const btmSheetOpen = (sheetRef: React.RefObject<HTMLDivElement>) => {
    const bottomSheet = sheetRef.current;
    if (bottomSheet) {
      bottomSheet.classList.add('active');
      setTimeout(() => {
        bottomSheet.classList.add('active_ani');
      }, 10);
    }
  };

  const btmSheetClose = (sheetRef: React.RefObject<HTMLDivElement>) => {
    const bottomSheet = sheetRef.current;
    if (bottomSheet) {
      bottomSheet.classList.remove('active_ani');
      setTimeout(() => {
        bottomSheet.classList.remove('active');
      }, 500);
    }
  };

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numbersOnly = value.replace(/[^0-9]/g, '');

    const maxLength = name === 'phoneFirst' ? 3 : 4;
    const truncatedValue = numbersOnly.slice(0, maxLength);
    formik.setFieldTouched('phoneNumber', true);
    formik.setFieldValue(name, truncatedValue);

    // 전체 전화번호 업데이트
    const phoneValues = {
      ...formik.values,
      [name]: truncatedValue,
    };

    formik.setFieldValue(
      'phoneNumber',
      `${phoneValues.phoneFirst}-${phoneValues.phoneMiddle}-${phoneValues.phoneLast}`
    );
  };

  const handleCompleteAddress = (data: string) => {
    formik.setFieldValue('address', data);
  };

  const handleNext = async () => {
    setFormikTouched(formik);
    const isValid = await validFormik(formik);
    formik.setFieldTouched('subscriptionMonth', true);
    if (!isValid) return;
    goTo(LONG_TRAVEL_INSURANCE_STEP4);
  };

  useEffect(() => {
    setStep3Values(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.isSame) {
      formik.setTouched({
        ...formik.touched,
        contractorName: false,
        contractorEnglishName: false,
        contractorSsnNumber: false,
        contractorSsnNumberFirst: false,
        contractorSsnNumberLast: false,
      });

      formik.setErrors({
        ...formik.errors,
        contractorName: undefined,
        contractorEnglishName: undefined,
        contractorSsnNumber: undefined,
        contractorSsnNumberFirst: undefined,
        contractorSsnNumberLast: undefined,
      });
      formik.setFieldValue('contractorName', formik.values.claimantName);
      formik.setFieldValue('contractorEnglishName', formik.values.englishName);
      formik.setFieldValue('contractorSsnNumber', formik.values.claimantSsnNumber);
      formik.setFieldValue('contractorSsnNumberFirst', formik.values.claimantSsnNumberFirst);
      formik.setFieldValue('contractorSsnNumberLast', formik.values.claimantSsnNumberLast);
    }
  }, [
    formik.values.claimantName,
    formik.values.englishName,
    formik.values.claimantSsnNumber,
    formik.values.claimantSsnNumberFirst,
    formik.values.claimantSsnNumberLast,
    formik.values.isSame,
  ]);

  console.log(isNative);

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='보험가입' />

        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide gray_bortop pb_28'>
            <div className='fs_16 fw_600 mt_28'>1. 보험가입자(피보험자)</div>

            <FormControl
              label='피보험자 이름'
              required
              hasError={!!formik.errors.claimantName && !!formik.touched.claimantName}
            >
              <FormInput formik={formik} name='claimantName' placeholder='이름을 입력해 주세요' />
            </FormControl>

            <FormControl
              label='영문 이름'
              required
              hasError={!!formik.errors.englishName && !!formik.touched.englishName}
            >
              <FormInput formik={formik} name='englishName' placeholder='이름 입력' />
              <p className='col_gray3 fs_14'>여권상의 영문명과 동일하게 작성해 주세요.</p>
            </FormControl>

            <FormControl
              label='피보험자 주민번호'
              required
              hasError={!!formik.errors.claimantSsnNumber && !!formik.touched.claimantSsnNumber}
            >
              <div className='dis_flex gap8'>
                <div className='inp flex1'>
                  <input
                    type='number'
                    placeholder='생년월일 6자리'
                    name='claimantSsnNumberFirst'
                    value={formik.values.claimantSsnNumberFirst}
                    onChange={onChangeSsnNumber}
                    onBlur={() => formik.setFieldTouched('claimantSsnNumber', true)}
                    style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                  />
                </div>
                <span>-</span>
                <div className='inp flex1'>
                  <input type='hidden' value={formik.values.claimantSsnNumberLast} ref={ref} />
                  <input
                    type='text'
                    placeholder='뒷 번호 7자리'
                    name='claimantSsnNumberLast'
                    value={maskedLastSsnNumber}
                    onChange={onChangeSsnNumber}
                    style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                  />
                </div>
              </div>
              {formik.errors.claimantSsnNumber && formik.touched.claimantSsnNumber && (
                <FormErrorMessage>{formik.errors.claimantSsnNumber}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              label='현재 거주지 주소'
              required
              hasError={!!formik.touched.addressDetail && !!formik.errors.addressDetail}
            >
              <div className='dis_flex gap8'>
                <div className='inp flex1'>
                  <input
                    type='text'
                    placeholder='기본주소'
                    value={formik.values.address}
                    readOnly
                  />
                </div>
                <Postcode callback={handleCompleteAddress} />
              </div>
              {formik.errors.address && formik.touched.address && (
                <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
              )}
              <FormInput formik={formik} name='addressDetail' placeholder='상세주소 입력' />
            </FormControl>

            <FormControl
              label='직업'
              required
              hasError={
                (!!formik.errors.job && !!formik.touched.job) ||
                (!!formik.touched.schoolName && !!formik.errors.schoolName)
              }
            >
              <div className='inp'>
                <button
                  className='select_ui_btn'
                  onClick={() => btmSheetOpen(bottomSheetRef1)} // 첫 번째 bottom sheet 열기
                >
                  {formik.values.job}
                </button>
              </div>
              {formik.values.job === '대학생 및 대학원생' && (
                <FormInput
                  formik={formik}
                  name='schoolName'
                  placeholder='재학중인 학교명을 입력해 주세요'
                />
              )}
            </FormControl>

            <FormControl
              label='휴대폰번호'
              required
              hasError={!!formik.errors.phoneNumber && !!formik.touched.phoneNumber}
            >
              <div className='dis_flex gap8'>
                <div className='inp flex1'>
                  <input
                    type='number'
                    name='phoneFirst'
                    placeholder='번호'
                    value={formik.values.phoneFirst}
                    onChange={onChangePhoneNumber}
                    pattern='[0-9]*'
                    style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                    onBlur={() => formik.setFieldTouched('phoneNumber', true)}
                  />
                </div>
                <span>-</span>
                <div className='inp flex1'>
                  <input
                    type='number'
                    name='phoneMiddle'
                    placeholder='번호'
                    value={formik.values.phoneMiddle}
                    onChange={onChangePhoneNumber}
                    pattern='[0-9]*'
                    style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                    onBlur={() => formik.setFieldTouched('phoneNumber', true)}
                  />
                </div>
                <span>-</span>
                <div className='inp flex1'>
                  <input
                    type='number'
                    name='phoneLast'
                    placeholder='번호'
                    value={formik.values.phoneLast}
                    onChange={onChangePhoneNumber}
                    pattern='[0-9]*'
                    style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                    onBlur={() => formik.setFieldTouched('phoneNumber', true)}
                  />
                </div>
                <input type='hidden' name='phoneNumber' value={formik.values.phoneNumber} />
              </div>
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              label='보험 가입 플랜'
              required
              hasError={!!formik.errors.plan && !!formik.touched.plan}
            >
              <FormInput formik={formik} name='plan' placeholder='이름 입력' disabled />
            </FormControl>

            <FormControl
              label='가입 개월'
              required
              hasError={!!formik.errors.subscriptionMonth && !!formik.touched.subscriptionMonth}
            >
              <FormInput
                formik={formik}
                name='subscriptionMonth'
                placeholder='개월수를 입력해 주세요'
                disabled
              />
            </FormControl>
          </div>

          <div className='guide gray_bortop'>
            <div
              className='fs_16 fw_600 mt_28'
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              2. 계약자 정보
              <label style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <input
                  type='checkbox'
                  style={{ width: '16px', height: '16px' }}
                  checked={formik.values.isSame}
                  onChange={e => formik.setFieldValue('isSame', e.target.checked)}
                />
                <span style={{ fontSize: '14px', fontWeight: '400' }}>
                  계약자와 피보험자가 동일합니다.
                </span>
              </label>
            </div>

            <FormControl
              label='계약자 이름'
              required
              hasError={!!formik.errors.contractorName && !!formik.touched.contractorName}
            >
              <FormInput
                formik={formik}
                name='contractorName'
                placeholder='이름 입력'
                disabled={formik.values.isSame}
              />
            </FormControl>

            <FormControl
              label='영문 이름'
              required
              hasError={
                !!formik.errors.contractorEnglishName && !!formik.touched.contractorEnglishName
              }
            >
              <FormInput
                formik={formik}
                name='contractorEnglishName'
                placeholder='이름 입력'
                disabled={formik.values.isSame}
              />
              <p className='col_gray3 fs_14'>여권상의 영문명과 동일하게 작성해 주세요.</p>
            </FormControl>
            <FormControl
              label='계약자 주민번호'
              required
              hasError={!!formik.errors.contractorSsnNumber && !!formik.touched.contractorSsnNumber}
            >
              <div className='dis_flex gap8'>
                <input
                  type='hidden'
                  name='contractorSsnNumber'
                  value={formik.values.contractorSsnNumber}
                />
                <div className='inp flex1'>
                  <input
                    type='number'
                    placeholder='생년월일 6자리'
                    name='contractorSsnNumberFirst'
                    value={formik.values.contractorSsnNumberFirst}
                    onChange={onChangeSsnNumber}
                    pattern='[0-9]*'
                    style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                    disabled={formik.values.isSame}
                  />
                </div>
                <span>-</span>
                <div className='inp flex1'>
                  <input
                    type='password'
                    placeholder='뒷 번호 7자리'
                    name='contractorSsnNumberLast'
                    value={formik.values.contractorSsnNumberLast}
                    onChange={onChangeSsnNumber}
                    pattern='[0-9]*'
                    style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                    disabled={formik.values.isSame}
                  />
                </div>
              </div>
            </FormControl>

            <div className='mt_24'>
              <p className='fs_16 fw_600'>가입증빙서류 받으실 이메일 주소를 입력해주세요.</p>
              <p className='mt_4 fs_14 col_gray3'>가입확인서(증명서), 약관 전송 시 사용됩니다.</p>
            </div>

            <FormControl
              label='이메일'
              required
              hasError={!!formik.errors.email && !!formik.touched.email}
            >
              <FormInput formik={formik} name='email' placeholder='이메일을 입력해 주세요' />
            </FormControl>
          </div>
        </div>

        <div className='foot_container'>
          <div className='foot_btn'>
            <button className={`btn blue`} onClick={handleNext}>
              다음
            </button>
          </div>
        </div>
      </div>
      <div className='bottom_sheet_pop' id='btmSheet01' ref={bottomSheetRef1}>
        <div className='pop_wrap pd_type2'>
          <div className='pop_tit'>유형 선택</div>
          <div className='pop_body'>
            <div className='checkbox_list02'>
              {JOB_LIST.map(job => (
                <div className='checkbox02' key={job}>
                  <input
                    type='radio'
                    name='job'
                    id={`rad0_${job}`}
                    onChange={() => {
                      formik.setFieldValue('job', job);
                      btmSheetClose(bottomSheetRef1);
                    }}
                    checked={formik.values.job === job}
                  />
                  <label htmlFor={`rad0_${job}`}>{job}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- bottom_sheet_pop --> */}
      <div className='bottom_sheet_pop' id='btmSheet02' ref={bottomSheetRef2}>
        <div className='pop_wrap pd_type2'>
          <div className='pop_tit'>플랜 선택</div>
          <div className='pop_body'>
            <div className='checkbox_list02'>
              {TEMP_PLAN_LIST.map(plan => (
                <PlanInput
                  key={plan}
                  value={plan}
                  onChange={() => {
                    formik.setFieldValue('plan', plan);
                    btmSheetClose(bottomSheetRef2);
                  }}
                  isSelected={formik.values.plan === plan}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LongTravelInsuranceStep3;

const PlanInput = ({
  value,
  onChange,
  isSelected,
}: {
  value: string;
  onChange: (value: string) => void;
  isSelected: boolean;
}) => {
  return (
    <div className='checkbox02'>
      <input
        type='radio'
        name='plan'
        id={`rad1_${value}`}
        onChange={() => onChange(value)}
        checked={isSelected}
      />
      <label htmlFor={`rad1_${value}`}>{value}</label>
    </div>
  );
};

const JOB_LIST = ['대학생 및 대학원생', '전업주부', '무직', '사무직', '기타'];
const TEMP_PLAN_LIST = ['W1', 'W3', 'W5', 'W7', 'W8', 'W10'];
