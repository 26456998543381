import React from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import { checkLogin } from '../../utils/auth';
import {
  DOMESTIC_TRAVEL_INSURANCE,
  GROUP_ACCIDENT_INSURANCE,
  LOGIN,
  LONG_TRAVEL_INSURANCE,
  OVERSEAS_TRAVEL_INSURANCE,
  PET,
} from '../../utils/routers';

const openExternalLink = (url: string) => {
  window.open(url, '_blank');
};

function Pc() {
  const { goTo } = useNavigateTo();

  return (
    <>
      <div className='pc_background'></div>
      <div className='quick_info'>
        <h1>
          SGIS KOREA
          <br />
          <em>안심보험 플랫폼</em>
        </h1>
        <p>복잡한 보험료 조회와 보상청구를 쉽고 간편하게!</p>
        <div className='btn_group'>
          <button onClick={() => goTo(checkLogin() ? GROUP_ACCIDENT_INSURANCE : LOGIN)}>
            단체상해
          </button>
          <button onClick={() => goTo(LONG_TRAVEL_INSURANCE)}>장기체류</button>
          <button onClick={() => goTo(OVERSEAS_TRAVEL_INSURANCE)}>해외여행</button>
          <button onClick={() => goTo(DOMESTIC_TRAVEL_INSURANCE)}>국내여행</button>
          <button onClick={() => goTo(PET)}>펫보험</button>
        </div>

        <div className='desc_container'>
          <p className='desc'>
            SGIS(Simon Global Insurance Service)는 고객과 같은 배를 타고 고난과 역경을 슬기롭게
            극복하여 희망의 목적지에 도달한다는 ‘同舟共濟’의 정신으로 한국과 일본, 동남아를 중심으로
            원보험 및 재보험 중개 서비스를 제공하는 Global Intermediary 입니다.
          </p>
        </div>
        <div className='site_container'>
          <p>
            Home Page :{' '}
            <em onClick={() => openExternalLink('https://simon-global.co.kr/')}>
              simon-global.co.kr
            </em>
          </p>
          <p className='fmaily_site_container'>
            <span>Family Site : </span>
            <div className='family_link_container'>
              <em onClick={() => openExternalLink('https://sgwemac.com/')}>SG위맥컨설팅</em>
              <em onClick={() => openExternalLink('http://www.simon-global.com/')}>SGIS 글로벌</em>
              <em onClick={() => openExternalLink('https://www.simon-global.co.jp/')}>SGIS 일본</em>
              <em onClick={() => openExternalLink('http://www.kongje.or.kr/')}>한국보험공제신문</em>
            </div>
          </p>
        </div>
      </div>
      <div className='quick_link'>
        <span className='sub_title'>앱 다운로드</span>
        <div className='btn_download_group'>
          <div
            className='quick_link_btn'
            onClick={() =>
              openExternalLink('https://apps.apple.com/kr/app/sgis-korea/id6738903247')
            }
          >
            <i className='icon_apple'></i>
            <span>App Store</span>
          </div>
          <div
            className='quick_link_btn'
            onClick={() =>
              openExternalLink('https://play.google.com/store/apps/details?id=com.sgisapp&hl=ko')
            }
          >
            <i className='icon_playstore'></i>
            <span>Play Store</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pc;
