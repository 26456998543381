import React, { useEffect, useMemo, useState } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import {
  OVERSEAS_TRAVEL_INSURANCE_DETAIL,
  OVERSEAS_TRAVEL_INSURANCE_STEP3,
} from '../../utils/routers';
import useOverseasTravelFunnel from '../../hooks/useOverseasTravelFunnel';
import { CompanionType } from '../../types/insurance/overseas-travle';
import useGuaranteInfo, { GuaranteInfo, PlanType } from '../../hooks/useGuaranteInfo';
import GuaranteeInfoCard from '../../components/GuaranteInfoCard';
import { calculateAge, formatSsnFirst } from '../../utils/format';
import { useFormik } from 'formik';
import { FormControl, FormInput } from '../../components/form-control/formControl';
import * as yup from 'yup';
import AlertModal from '../../components/modal/AlertModal';
import { BIRTH_DATE_SCHEMA } from '../../constants/validationSchemas';

function OverseasTravelInsuranceStep2() {
  const { goTo } = useNavigateTo();
  const { step2Values, setStep2Values, step1Values } = useOverseasTravelFunnel();
  const plan: PlanType[] = useMemo(() => ['A1', 'A2', 'A3', 'A4', 'A5'], []);
  const { data: guaranteInfo } = useGuaranteInfo(
    'OVERSEAS_TRAVEL',
    plan,
    step1Values?.subscriptionDays
  );
  const [errorModalDesc, setErrorModalDesc] = useState('');

  const processGuaranteInfo = (data: GuaranteInfo[]) => {
    return data.reduce(
      (acc, curr) => {
        if (!acc[curr.plan]) {
          acc[curr.plan] = {
            plan: curr.plan,
            recommendedCountries: curr.recommendedCountries,
            benefits: [],
          };
        }

        if (acc[curr.plan].benefits.length < 3) {
          acc[curr.plan].benefits.push({
            title: curr.compensationContent,
            amount: curr.amount,
          });
        }

        return acc;
      },
      {} as Record<
        string,
        {
          plan: string;
          recommendedCountries: string;
          benefits: { title: string; amount: string }[];
        }
      >
    );
  };

  const processedData = useMemo(() => {
    if (!guaranteInfo?.data?.result) return [];
    return Object.values(processGuaranteInfo(guaranteInfo.data.result));
  }, [guaranteInfo]);

  const handleItemClick = (plan: string) => {
    setStep2Values({
      ...step2Values,
      plan,
    });
  };

  const deleteCompanion = (key: number) => {
    const filteredCompanions = step2Values?.companion?.filter(c => c.key !== key) || [];

    const reorderedCompanions = filteredCompanions.map((companion, index) => ({
      ...companion,
      key: filteredCompanions.length - index,
    }));

    setStep2Values({
      ...step2Values,
      companion: reorderedCompanions,
    });
  };

  const addCompanion = () => {
    setStep2Values({
      ...step2Values,
      companion: [
        {
          key:
            (step2Values?.companion?.reduce((max, current) => Math.max(max, current.key), 0) || 0) +
            1,
          companionName: '',
          companionSsnNumber: '',
          companionEnglishName: '',
          gender: undefined,
          isEdit: true,
        },
        ...(step2Values?.companion || []),
      ],
    });
  };

  const onSaveCompanion = (formValue: CompanionType) => {
    setStep2Values({
      ...step2Values,
      companion:
        step2Values?.companion?.map(comp => (comp.key === formValue.key ? formValue : comp)) || [],
    });
  };

  const isValidNext = () => {
    const isValidCompanion = step2Values?.companion?.every(
      companion =>
        companion.companionName &&
        companion.companionSsnNumberFirst &&
        companion.gender !== undefined &&
        companion.isEdit !== true
    );

    const isNotOver10 = (step2Values?.companion?.length || 0) < 11;

    return isValidCompanion && isNotOver10;
  };

  const onClickNext = () => {
    if (!step2Values?.plan) {
      setErrorModalDesc('보험 유형을 선택해주세요.');
      return;
    }
    if (!isValidNext()) return;
    goTo(OVERSEAS_TRAVEL_INSURANCE_STEP3);
  };

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='보험료 조회' />

        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container gray02'>
          <div className='guide'>
            <div className='mt_28'>
              <p className='fs_18 fw_600'>동반자 추가</p>

              {step2Values?.companion?.map((companion, index) => (
                <CompanionForm
                  key={companion.key}
                  companion={companion}
                  onDelete={deleteCompanion}
                  onSave={onSaveCompanion}
                />
              ))}
              <div className='mt_12'>
                <button
                  className='btn blue'
                  onClick={addCompanion}
                  disabled={(step2Values?.companion?.length || 0) >= 10}
                >
                  동반자 추가&nbsp;
                  <span className='fw_400'>(최대 10명)</span>
                </button>
              </div>
            </div>

            <div className='price_check_list mt_20 pb_32'>
              {processedData?.map(item => (
                <GuaranteeInfoCard
                  key={item.plan}
                  selectedPlan={step2Values?.plan}
                  data={item}
                  handleItemClick={handleItemClick}
                  link={`${OVERSEAS_TRAVEL_INSURANCE_DETAIL}?t=${item.plan}`}
                  feeData={{
                    infoType: 'OVERSEAS_TRAVEL',
                    ipBirthDate: step1Values?.ipBirthDate || '',
                    gender: step1Values?.gender ? 1 : 0,
                    day: step1Values?.subscriptionDays || '',
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <div className='foot_btn'>
            <button className='btn blue' onClick={onClickNext} disabled={!isValidNext()}>
              보험 가입
            </button>
          </div>
        </div>
        {/* <!-- // foot_container --> */}
      </div>
      <AlertModal
        title='보험 유형을 선택해주세요.'
        desc={errorModalDesc}
        onClose={() => setErrorModalDesc('')}
        sx={{ display: errorModalDesc ? 'block' : 'none' }}
      />
    </>
  );
}

export default OverseasTravelInsuranceStep2;

const companionSchema = yup.object({
  companionName: yup
    .string()
    .trim()
    .min(2, '최소 2자 이상 입력해주세요.')
    .required('이름을 입력해주세요'),
  companionSsnNumberFirst: BIRTH_DATE_SCHEMA,

  gender: yup.boolean().required('성별을 선택해주세요'),
});

interface CompanionFormProps {
  companion: CompanionType;
  onDelete: (key: number) => void;
  onSave: (formValue: CompanionType) => void;
}

export const CompanionForm = ({ companion, onDelete, onSave }: CompanionFormProps) => {
  const formik = useFormik({
    initialValues: companion,
    onSubmit: () => {},
    validationSchema: companionSchema,
    validateOnMount: true,
  });

  const handleEditMode = () => {
    onSave({ ...companion, isEdit: true });
  };

  const onClickSave = () => {
    const saveValue = { ...companion, isEdit: false };
    onSave(saveValue);
  };

  useEffect(() => {
    onSave(formik.values);
  }, [formik.values]);

  return (
    <>
      <div className='mt_12'>
        {companion.isEdit ? (
          <div className='add_companion'>
            <div className='dis_flex justify_between'>
              <p className='fs_16 fw_600'>동반자 {companion.key}</p>
              <button className='x_icon' onClick={() => onDelete(companion.key)}></button>
            </div>
            <FormControl
              required
              label='이름'
              hasError={!!formik.errors.companionName && formik.touched.companionName}
            >
              <FormInput
                formik={formik}
                name='companionName'
                placeholder='이름을 입력해 주세요'
                value={formik.values.companionName}
                onChange={e => {
                  const value = e.target.value;
                  formik.setFieldValue('companionName', value);
                }}
              />
            </FormControl>

            <div className='dis_flex gap12 mt_12'>
              <FormControl
                required
                label='피보험자 생년월일'
                hasError={
                  !!formik.errors.companionSsnNumberFirst && formik.touched.companionSsnNumberFirst
                }
                className='mt_0 flex1'
              >
                <FormInput
                  formik={formik}
                  name='companionSsnNumberFirst'
                  placeholder='예시: 980424'
                  value={formik.values.companionSsnNumberFirst}
                  onChange={e => {
                    const formattedValue = formatSsnFirst(e);
                    formik.setFieldValue('companionSsnNumberFirst', formattedValue);
                  }}
                />
              </FormControl>

              <FormControl required label='성별' className='flex1'>
                <div className='dis_flex gap12'>
                  <div className='flex1'>
                    <input
                      type='radio'
                      name='gender'
                      id={`male-${companion.key}`}
                      value='true'
                      className='hide'
                      checked={formik.values.gender === true}
                      onChange={e => {
                        formik.setFieldValue('gender', true);
                      }}
                    />
                    <label htmlFor={`male-${companion.key}`} className='btn gray02'>
                      남성
                    </label>
                  </div>
                  <div className='flex1'>
                    <input
                      type='radio'
                      name='gender'
                      id={`female-${companion.key}`}
                      value='false'
                      className='hide'
                      checked={formik.values.gender === false}
                      onChange={e => {
                        formik.setFieldValue('gender', false);
                      }}
                    />
                    <label htmlFor={`female-${companion.key}`} className='btn gray02'>
                      여성
                    </label>
                  </div>
                </div>
              </FormControl>
            </div>
            <button className={'btn blue mt_16'} onClick={onClickSave} disabled={!formik.isValid}>
              등록
            </button>
          </div>
        ) : (
          <div className='add_companion'>
            <div className='dis_flex justify_between'>
              <p className='fs_18 fw_600'>동반자 {companion.key}</p>
              <div>
                <button className='edit_icon mr_15' onClick={handleEditMode}></button>
                <button className='x_icon' onClick={() => onDelete(companion.key)}></button>
              </div>
            </div>
            <div className='info_txt mt_8'>
              <p>이름: {companion.companionName}</p>
              <p>나이: {calculateAge(companion.companionSsnNumberFirst || '')}</p>
              <p>성별: {companion.gender ? '남성' : '여성'}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
