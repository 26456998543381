import { useState, useEffect } from 'react';

const useIsNativeWebView = () => {
  const [isNativeWebView, setIsNativeWebView] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    // Android WebView: 'wv' 포함
    if (userAgent.includes('wv')) {
      setIsNativeWebView(true);
      return;
    }

    // iOS WebView 감지 수정
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isSafari = /safari/.test(userAgent);

    if (isIOS && !isSafari) {
      setIsNativeWebView(true);
      return;
    }

    setIsNativeWebView(false);
  }, []);

  return isNativeWebView;
};

export default useIsNativeWebView;
