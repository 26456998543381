import React, { useRef, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import useBottomSheet from '../../hooks/useBottomSheet';
import useIsNativeWebView from '../../hooks/useIsNativeWebView';
import DocumentsSheet from '../../components/common/DocumentsSheet';
import useMessageHandler, { FileData } from '../../hooks/useMessageHandler';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import { useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { base64ToFile, debugFormData } from '../../utils/file';
import AlertModal from '../../components/modal/AlertModal';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';

interface CreateGroupAccidentInsuranceClaimResponse {
  status: boolean;
  data: {
    insuranceClaimId: number;
  };
}

function ClaimAccientStep5() {
  const { goTo } = useNavigateTo();
  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();
  const isNative = useIsNativeWebView();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachedDocumentError, setAttachedDocumentError] = useState('');
  const [successModalDesc, setSuccessModalDesc] = useState('');

  const { finalValues, clearForm } = useGroupAccidentFunnel();

  const { files, setFiles, addFile } = useMessageHandler(() => toggleBottomSheet(false));

  const createGroupAccidentInsuranceClaimMutation =
    useCustomMutation<CreateGroupAccidentInsuranceClaimResponse>(
      endpointConfig.groupAccidentInsuranceClaim,
      'post',
      1
    );

  const createAttachedDocumentsMutation =
    useCustomMutation<CreateGroupAccidentInsuranceClaimResponse>(
      endpointConfig.createAttachedDocument,
      'post',
      0
    );

  const createFormData = () => {
    const formData = new FormData();
    formData.append('ipName', finalValues.ipName);
    formData.append('ipSsnNumber', finalValues.ipSsnNumber);
    formData.append('eName', finalValues.eName);
    formData.append('eSsnNumber', finalValues.eSsnNumber);
    formData.append('ciName', finalValues.ciName);
    formData.append('ciPhone', finalValues.ciPhone);
    formData.append('accidentType', finalValues.accidentType.toString());
    formData.append('accidentDay', finalValues.accidentDay);
    formData.append('diagnosis', finalValues.diagnosis);
    // 사고유형 상해 일 경우만 추가
    if (finalValues.accidentType === 1) {
      formData.append('accidentLocation', finalValues?.accidentLocation || '');
      formData.append('accidentDetails', finalValues.accidentDetails || '');
    }
    formData.append('invoiceTransfer', finalValues.invoiceTransfer.toString());
    if (finalValues.invoiceTransfer) {
      finalValues.invoiceTransferCompany?.forEach(company => {
        formData.append('invoiceTransferCompany[]', company);
      });
    }
    formData.append('accountHolder', finalValues.accountHolder);
    formData.append('accountHolderSsnNumber', finalValues.accountHolderSsnNumber);
    formData.append('bankName', finalValues.bankName);
    formData.append('accountNumber', finalValues.accountNumber);
    formData.append('serviceStatus', finalValues.serviceStatus.toString());

    formData.append('claimantName', finalValues.ipName);
    if (finalValues.claimantSignaturePreview) {
      formData.append(
        'claimantSignature',
        base64ToFile(finalValues.claimantSignaturePreview, 'claimantSignature', 'image/png')
      );
    }

    if (finalValues.ipSignaturePreview) {
      formData.append(
        'ipSignature',
        base64ToFile(finalValues.ipSignaturePreview, 'ipSignature', 'image/png')
      );
    }

    if (finalValues.ibName) {
      formData.append('ibName', finalValues.ibName);
    }
    if (finalValues.ibSignaturePreview) {
      formData.append(
        'ibSignature',
        base64ToFile(finalValues.ibSignaturePreview, 'ibSignature', 'image/png')
      );
    }
    if (finalValues.lrName) {
      formData.append('lrName', finalValues.lrName);
    }
    if (finalValues.lrSignaturePreview) {
      formData.append(
        'lrSignature',
        base64ToFile(finalValues.lrSignaturePreview, 'lrSignature', 'image/png')
      );
    }

    return formData;
  };

  const createAttachedDocumentsFormData = (insuranceClaimId: number, fileIndex: number) => {
    const formData = new FormData();
    const fileLength = files.length;
    console.log('files :', files);
    formData.append('insuranceClaimId', insuranceClaimId.toString());
    const file = files[fileIndex].originalFile;
    console.log(files[fileIndex].originalFile);
    if (!file) return;

    formData.append('documentaryEvidence', file);
    formData.append('lastYn', (fileLength === fileIndex + 1).toString());
    return formData;
  };

  const postAttachedDocuments = async (insuranceClaimId: number) => {
    let isSuccess = true;
    files.forEach((_, idx) => {
      const attachedDocumentsFormData = createAttachedDocumentsFormData(insuranceClaimId, idx);
      debugFormData(attachedDocumentsFormData as FormData);
      createAttachedDocumentsMutation.mutateAsync(attachedDocumentsFormData, {
        onSuccess: response => {
          if (!response.status) {
            isSuccess = false;
          }
        },
      });
    });
    return isSuccess;
  };

  const onSubmit = () => {
    if (files.length === 0) {
      setAttachedDocumentError('증빙서류를 첨부해 주세요');
      return;
    }
    const formData = createFormData();

    createGroupAccidentInsuranceClaimMutation.mutate(formData, {
      onSuccess: async response => {
        const { insuranceClaimId } = response.data;

        const isSuccess = await postAttachedDocuments(insuranceClaimId);
        console.log('isSuccess :', isSuccess);
        if (isSuccess) {
          setSuccessModalDesc('보험금 청구 접수를 성공적으로\n완료하였습니다');
          clearForm();
        }
      },
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);
      for (const file of fileArray) {
        try {
          await addFile(file); // 성공적으로 추가된 파일만 처리
        } catch (error) {
          console.error('파일 추가 중 오류:', error); // 오류를 명시적으로 처리
        }
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(prevFiles => prevFiles.filter((_, idx) => idx !== index));
  };

  const onCloseSuccessModal = () => {
    clearForm();
    goTo('/');
    setSuccessModalDesc('');
  };

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='단체상해보험' hasTolltip={true} tooltipContent={<TooltipContent />} />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='fs_18 fw_600 mt_14'>7. 증빙서류 첨부</div>
            <p className='mt_14 fs_14 col_gray'>사용하실 증빙 서류 이미지를 추가해 주세요.</p>
            <div className='mt_16'>
              <button
                className='btn blue_border'
                onClick={() => {
                  if (isNative) toggleBottomSheet(true);
                  else fileInputRef.current?.click();
                }}
              >
                추가하기
              </button>
            </div>

            {files.length > 0 && (
              <div className='inner_gray_box square mt_18'>
                {files.map((file, idx) => (
                  <FileList file={file} handleRemoveFile={() => handleRemoveFile(idx)} />
                ))}
              </div>
            )}
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <div className='foot_btn'>
            <button
              className={`btn blue`}
              onClick={onSubmit}
              // onclick="popOpen('#alert01')"
            >
              {createAttachedDocumentsMutation.isLoading ||
              createGroupAccidentInsuranceClaimMutation.isLoading
                ? '처리중'
                : '완료'}
            </button>
          </div>
        </div>
        {/* <!-- // foot_container --> */}
      </div>

      {/* <!-- bottom_sheet_pop --> */}
      {/* input 요소는 보이지 않게 설정 */}
      <input
        type='file'
        accept='image/*'
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      {isNative && (
        <DocumentsSheet bottomSheetRef={bottomSheetRef} toggleBottomSheet={toggleBottomSheet} />
      )}

      {/* <!-- alert_pop --> */}
      <AlertModal
        title='증빙서류 첨부'
        desc={attachedDocumentError}
        onClose={() => setAttachedDocumentError('')}
        sx={{ display: attachedDocumentError ? 'block' : 'none' }}
      />

      {/* <!-- alert_pop --> */}
      <SuccessModal
        title='접수 완료'
        desc={successModalDesc}
        onClose={onCloseSuccessModal}
        sx={{ display: successModalDesc ? 'block' : 'none' }}
      />
    </>
  );
}

export default ClaimAccientStep5;

interface FileListProps {
  file: FileData;
  handleRemoveFile: () => void;
}

const FileList = ({ file, handleRemoveFile }: FileListProps) => {
  return (
    <div className='txt_type02'>
      {file.name}
      <i className='x_icon_02' onClick={handleRemoveFile}></i>
    </div>
  );
};

interface SuccessModalProps {
  title: string;
  desc: string;
  onClose: () => void;
  sx: any;
}

const SuccessModal = ({ title, desc, onClose, sx }: SuccessModalProps) => {
  return (
    <div className='alert_pop' id='alert02' style={sx}>
      <div className='pop_wrap'>
        <div className='pop_tit'>{title}</div>
        <div className='pop_body'>
          <div className='msg'>{desc}</div>
        </div>
        <div className='pop_btn'>
          <button className='btn black' onClick={onClose}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
