import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LONG_TRAVEL_INSURANCE_STEP2 } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import useBottomSheet from '../../hooks/useBottomSheet';
import {
  FormControl,
  FormDatePicker,
  FormErrorMessage,
  FormInput,
} from '../../components/form-control/formControl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LongTravelStep1ValuesType } from '../../types/insurance/long-travel';
import useLongTravelFunnel from '../../hooks/useLongTravelFunnel';
import useLongStayContinent from '../../hooks/useLongStayContinet';
import Terms, { TERMS_LIST } from '../../components/Terms';
import {
  BIRTH_DATE_SCHEMA,
  COUNTRY_SCHEMA,
  DATE_SCHEMA,
  GENDER_SCHEMA,
  PLACE_OF_STAY_SCHEMA,
  TRAVEL_PURPOSE_SCHEMA,
} from '../../constants/validationSchemas';
import { formatSsnFirst } from '../../utils/format';
import { setFormikTouched, validFormik } from '../../utils/formik';
import dayjs from 'dayjs';
import { checkTravelPeriodWithRecommendation } from '../../utils/checkMonth';

const longTravelInsuranceStep1Schema = yup.object({
  ipBirthDate: BIRTH_DATE_SCHEMA,
  gender: GENDER_SCHEMA,
  startDate: DATE_SCHEMA('출발'),
  endDate: DATE_SCHEMA('도착'),
  country: COUNTRY_SCHEMA,
  continent: yup.string().required('대륙을 선택해주세요.'),
  placeOfStay: PLACE_OF_STAY_SCHEMA,
  travelPurpose: TRAVEL_PURPOSE_SCHEMA,
  subscriptionDays: yup.string().required('가입일수를 선택해주세요.'),
});

function LongTravelInsuranceStep1() {
  const { goTo } = useNavigateTo();
  const { step1Values, setStep1Values, step3Values, setStep3Values } = useLongTravelFunnel();
  const [checkedList, setCheckedList] = useState<string[]>(
    step1Values?.isAgree ? TERMS_LIST.map(item => item.key) : []
  );
  const [isDirty, setIsDirty] = useState(false);

  const formik = useFormik<LongTravelStep1ValuesType>({
    initialValues: { ...step1Values } as LongTravelStep1ValuesType,
    validationSchema: longTravelInsuranceStep1Schema,
    onSubmit: () => {},
  });

  const { data: continentData } = useLongStayContinent({
    continent: CONTINENT_LIST.findIndex(continentName => continentName === formik.values.continent),
  });

  const { bottomSheetRef: bottomSheetRef1, toggleBottomSheet: toggleBottomSheet1 } =
    useBottomSheet();
  const { bottomSheetRef: bottomSheetRef2, toggleBottomSheet: toggleBottomSheet2 } =
    useBottomSheet();

  const handleCheck = (key: string) => {
    if (checkedList.includes(key)) {
      setCheckedList(prev => prev.filter(item => item !== key));
    } else {
      setCheckedList(prev => [...prev, key]);
    }
  };

  const handleAllCheck = () => {
    setCheckedList(prev =>
      prev.length === TERMS_LIST.length ? [] : TERMS_LIST.map(item => item.key)
    );
  };

  const checkSubscriptionDays = useMemo(() => {
    const startDate = formik.values.startDate;
    const endDate = formik.values.endDate;
    if (!startDate?.split(' ')[0] || !endDate?.split(' ')[0]) return;

    const result = checkTravelPeriodWithRecommendation({
      startDate,
      endDate,
      travelType: 'LONG_TERM',
    });

    formik.setFieldValue('subscriptionDays', result.recommendedPeriod?.key);
    setStep3Values({ ...step3Values, subscriptionMonth: result.travelDays });

    return result;
  }, [formik.values.startDate, formik.values.endDate]);

  const handleNext = async () => {
    setFormikTouched(formik);
    setIsDirty(true);
    formik.setFieldTouched('gender', true);
    const isValid = (await validFormik(formik)) && checkedList.length === TERMS_LIST.length;
    if (!isValid) return;
    const hasErrors = Object.keys(formik.errors).length > 0;
    setStep1Values({
      ...step1Values,
      isAgree: checkedList.length === TERMS_LIST.length,
    });

    if (checkSubscriptionDays?.message) return;

    if (isValid && !hasErrors) {
      goTo(LONG_TRAVEL_INSURANCE_STEP2);
    }
  };

  useEffect(() => {
    setStep1Values(formik.values);
  }, [formik.values]);

  return (
    <div className='wrap'>
      <DetailHeader title='해외장기체류보험' />
      <div className='container'>
        <div className='guide gray_bortop pb_28'>
          <FormControl
            label='피보험자 생년월일'
            required
            hasError={!!formik.errors.ipBirthDate && formik.touched.ipBirthDate}
          >
            <FormInput
              formik={formik}
              name='ipBirthDate'
              placeholder='생년월일 6자리'
              type='number'
              onChange={e => {
                const formattedValue = formatSsnFirst(e);
                formik.setFieldValue('ipBirthDate', formattedValue);
              }}
            />
          </FormControl>
          <FormControl label='성별' required hasError={!!formik.errors.gender}>
            <div className='dis_flex gap12'>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad01'
                  className='hide'
                  checked={formik.values.gender === true}
                  onChange={e => formik.setFieldValue('gender', e.target.checked)}
                />
                <label
                  htmlFor='rad01'
                  className='btn gray02'
                  onClick={() => formik.setFieldValue('gender', true)}
                >
                  남성
                </label>
              </div>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad02'
                  className='hide'
                  checked={formik.values.gender === false}
                  onChange={e => formik.setFieldValue('gender', e.target.checked)}
                />
                <label
                  htmlFor='rad02'
                  className='btn gray02'
                  onClick={() => formik.setFieldValue('gender', false)}
                >
                  여성
                </label>
              </div>
            </div>
          </FormControl>
          {formik.errors.gender && formik.touched.gender && (
            <div className='state_txt_error'>{String(formik.errors.gender)}</div>
          )}
          <FormControl
            label='보험개시일 (한국에서 출발 기준)'
            hasError={!!formik.errors.startDate && formik.touched.startDate}
            required
          >
            <FormDatePicker
              selectedDate={formik.values.startDate?.split(' ')[0] || ''}
              onDateChange={e => {
                formik.setFieldValue(
                  'startDate',
                  `${e} ${formik.values.startDate?.split(' ')[1] || '00:00'}`
                );
                if (dayjs(formik.values.endDate).isBefore(dayjs(e))) {
                  formik.setFieldValue(
                    'endDate',
                    `${e} ${formik.values.endDate?.split(' ')[1] || '00:00'}`
                  );
                }
              }}
              selectedTime={formik.values.startDate?.split(' ')[1] || ''}
              onTimeChange={time =>
                formik.setFieldValue(
                  'startDate',
                  `${formik.values.startDate?.split(' ')[0]} ${time}`
                )
              }
              hasError={!!formik.errors.startDate && formik.touched.startDate}
              enableTimePicker
            />
          </FormControl>
          {formik.errors.startDate && formik.touched.startDate && (
            <div className='state_txt_error'>{String(formik.errors.startDate)}</div>
          )}
          <FormControl
            label='보험종료일 (한국 도착일 기준)'
            required
            hasError={!!formik.errors.endDate && formik.touched.endDate}
          >
            <FormDatePicker
              selectedDate={formik.values.endDate?.split(' ')[0] || ''}
              onDateChange={e => {
                formik.setFieldValue(
                  'endDate',
                  `${e} ${formik.values.endDate?.split(' ')[1] || '00:00'}`
                );
              }}
              selectedTime={formik.values.endDate?.split(' ')[1] || ''}
              onTimeChange={time =>
                formik.setFieldValue(`endDate`, `${formik.values.endDate?.split(' ')[0]} ${time}`)
              }
              hasError={!!formik.errors.endDate && formik.touched.endDate}
              enableTimePicker
            />
          </FormControl>

          {formik.errors.endDate && formik.touched.endDate && (
            <div className='state_txt_error'>{String(formik.errors.endDate)}</div>
          )}

          {checkSubscriptionDays?.message && (
            <div className='state_txt_error'>{checkSubscriptionDays.message}</div>
          )}

          <div className='inp_wrap mt_28'>
            <label htmlFor='main-category'>
              여행 국가 <span className='col_red'>*</span>
            </label>
            <div className='dis_flex gap12'>
              <div className='flex1'>
                <select
                  style={{
                    borderColor:
                      !!formik.errors.continent && formik.touched.continent ? '#DC0000' : '',
                  }}
                  id='main-category'
                  onMouseDown={(event: React.MouseEvent<HTMLSelectElement>) => {
                    event.preventDefault(); // 기본 동작 차단
                    event.stopPropagation();
                    toggleBottomSheet1(true);
                  }}
                >
                  <option value=''>{formik.values.continent || '대륙 선택'}</option>
                </select>
              </div>
              <div className='flex1'>
                <select
                  id='sub-category'
                  onMouseDown={(event: React.MouseEvent<HTMLSelectElement>) => {
                    event.preventDefault(); // 기본 동작 차단
                    event.stopPropagation();
                    if (formik.values.continent !== undefined) {
                      toggleBottomSheet2(true);
                    }
                  }}
                  disabled={formik.values.continent === ''}
                  style={{
                    borderColor: !!formik.errors.country && formik.touched.country ? '#DC0000' : '',
                  }}
                >
                  <option value=''>{formik.values.country || '국가 선택'}</option>
                </select>
              </div>
            </div>{' '}
            {!!formik.errors.continent && formik.touched.continent && (
              <FormErrorMessage>{formik.errors.continent}</FormErrorMessage>
            )}
            {!!formik.errors.country && formik.touched.country && !formik.errors.continent && (
              <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
            )}
            <div className='fs_11 col_gray'>
              &nbsp;&nbsp;·&nbsp;&nbsp;여러 국가 여행 시 대표 국가를 선택해 주세요.
            </div>
          </div>

          <FormControl
            label='현재 체류지'
            required
            hasError={!!formik.errors.placeOfStay && formik.touched.placeOfStay}
          >
            <div className='inp'>
              <select
                name='placeOfStay'
                onClick={() => formik.setFieldTouched('placeOfStay', true)}
                onChange={e => formik.setFieldValue('placeOfStay', e.target.value)}
                value={formik.values.placeOfStay}
                onBlur={formik.handleBlur}
                style={{
                  borderColor:
                    !!formik.errors.placeOfStay && formik.touched.placeOfStay ? '#DC0000' : '',
                }}
              >
                <option value=''>선택</option>
                <option value='국내'>국내</option>
                <option value='해외'>해외</option>
              </select>
            </div>
            {!!formik.errors.placeOfStay && formik.touched.placeOfStay && (
              <FormErrorMessage>{formik.errors.placeOfStay}</FormErrorMessage>
            )}
            <div className='fs_11 col_gray'>
              &nbsp;&nbsp;·&nbsp;&nbsp;현재 체류하고 계신 지역을 선택해 주세요.
            </div>
          </FormControl>

          <FormControl
            label='여행 목적'
            required
            hasError={!!formik.errors.travelPurpose && formik.touched.travelPurpose}
          >
            <div className='dis_flex gap20 column mt_8'>
              {TRAVEL_PURPOSE_LIST.map((travelPurpose, index) => (
                <div
                  className='radio w_full'
                  key={travelPurpose}
                  onClick={() => formik.setFieldValue('travelPurpose', travelPurpose)}
                >
                  <input
                    id={`rad${travelPurpose}`}
                    name='travelPurpose'
                    type='radio'
                    checked={formik.values.travelPurpose === travelPurpose}
                    onChange={e => formik.setFieldValue('travelPurpose', e.target.value)}
                  />
                  <label htmlFor={`rad${travelPurpose}`}>{travelPurpose}</label>
                </div>
              ))}
            </div>
            {!!formik.errors.travelPurpose && formik.touched.travelPurpose && (
              <FormErrorMessage>{formik.errors.travelPurpose}</FormErrorMessage>
            )}
          </FormControl>
        </div>

        <div className='guide gray_bortop'>
          <div className='fs_18 fw_600 mt_28'>개인정보 수집 · 활용 및 제공을 위한 동의</div>

          <Terms
            checkedList={checkedList}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
          />
          {isDirty && checkedList.length !== 4 && (
            <div className='state_txt_error'>모두 동의해주세요.</div>
          )}
        </div>

        <div className='mt_36 px_16'>
          <p
            className='px_18 py_20 fw_400 fs_14'
            style={{ background: '#F8F8F8', borderRadius: '6px', color: '#4D4D4D' }}
          >
            외교부가 지정하는 지역별 여행경보 단계 중 적색경보, 흑색경보 지역 및 특별여행주의보
            지역을 방문하실 경우 보험가입과 보상이 불가능합니다. 최신정보를 확인하기 위해 개별적으로
            외교부 해외안전여행 사이트(www.0404.go.kr)서 여행 예정 국가를 확인하시기 바랍니다.
          </p>
        </div>
      </div>

      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={handleNext}>
            보험료 조회
          </button>
        </div>
      </div>

      {/* bottom sheet 2*/}
      <div className='bottom_sheet_pop' id='btmSheet01' ref={bottomSheetRef1}>
        <div className='pop_wrap pd_type2'>
          <div className='pop_tit'>대륙 선택</div>
          <div className='pop_body pop_max_height'>
            <div className='checkbox_list02'>
              {CONTINENT_LIST.map((continentName, index) => {
                return (
                  <div
                    className='checkbox02'
                    key={continentName}
                    onClick={() => {
                      formik.setFieldValue('continent', continentName);
                      formik.setFieldValue('country', '');
                      toggleBottomSheet1(false);
                    }}
                  >
                    <input
                      type='radio'
                      name='continent'
                      id={`rad${index + 1}`}
                      value={continentName}
                      checked={formik.values.continent === continentName}
                    />
                    <label htmlFor={`rad${index + 1}`}>{continentName}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* bottom sheet 1*/}
      <div className='bottom_sheet_pop' id='btmSheet01' ref={bottomSheetRef2}>
        <div className='pop_wrap pd_type2'>
          <div className='pop_tit'>국가 선택</div>
          <div className='pop_body pop_max_height'>
            <div className='checkbox_list02'>
              {continentData?.data.result?.map((country, index) => {
                return (
                  <div className='checkbox02'>
                    <input
                      type='radio'
                      name='stayCountry'
                      id={`rad${country.name}`}
                      value={country.name}
                      checked={formik.values.country === country.name}
                    />
                    <label
                      htmlFor={`rad${country.name}`}
                      onClick={() => {
                        formik.setFieldValue('country', country.name);
                        toggleBottomSheet2(false);
                      }}
                    >
                      {country.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LongTravelInsuranceStep1;

const CONTINENT_LIST = ['유럽', '아시아', '아프리카', '오세아니아', '남아메리카', '북아메리카'];
const TRAVEL_PURPOSE_LIST = [
  '유학/어학연수',
  '출장/주재원/교환교수',
  '워킹홀리데이',
  '해외장기체류(일반관광/친지방문)',
];
