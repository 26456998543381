import React, { useMemo } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useQueryParam from '../../hooks/useQueryParam';
import InsuranceTable from '../../components/domesticTravelInsurance/InsuranceTable';
import useGuaranteInfo, { PlanType } from '../../hooks/useGuaranteInfo';
import AllInsuranceInfo from '../../components/domesticTravelInsurance/AllInsuranceInfo';

function DomesticTravelInsuranceDetail() {
  const tValue = useQueryParam('t') as PlanType | 'all'; // 보장 타입
  const plan = useMemo(
    () => (tValue === 'all' ? ['상해/질병(혼합형)', '상해(알뜰형)'] : [tValue]),
    [tValue]
  ) as PlanType[];
  const isAll = tValue === 'all';

  const { data: guaranteInfo } = useGuaranteInfo('DOMESTIC_TRAVEL', plan);

  return (
    <div className='bg_white'>
      {/* <!-- header --> */}
      <DetailHeader title={`${isAll ? '전체' : tValue} 보장 내용`} />
      {/* <!-- // header --> */}

      <div className='px_15'>
        {isAll ? <AllInsuranceInfo /> : <InsuranceTable plan={guaranteInfo?.data.result || []} />}
      </div>
    </div>
  );
}

export default DomesticTravelInsuranceDetail;
