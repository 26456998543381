import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { useParams } from 'react-router-dom';
import { getRequiredDocTxt } from '../../utils/requiredDoc';
import useNavigateTo from '../../hooks/useNavigateTo';
import { REQUIRED_DOC_INFO } from '../../utils/routers';
import { DocumentProps, Document as PDFDocument, Page, PageProps, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js`;
const PDFDocumentComponent = PDFDocument as unknown as React.ComponentType<DocumentProps>;
const PDFPageComponent = Page as unknown as React.ComponentType<PageProps>;

function RequiredDocInfoDetail() {
  const { id } = useParams();
  const { goTo } = useNavigateTo();

  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    if (id) {
      setPdfUrl(`/document/${id}.pdf`); // 선택된 id에 맞는 파일 경로 설정
    }
  }, [id]);

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title={getRequiredDocTxt(id as string)} path={REQUIRED_DOC_INFO} />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container gray02 required_pdf_container'>
          <div className='guide bg_white'>
            <div className='tab_btns_wrap type01'>
              <div className='tab_btns'>
                {[
                  { txt: '상해후유증', path: 'PAI' },
                  { txt: '상해사망', path: 'ADI' },
                  { txt: '질병사망', path: 'IDD' },
                  { txt: '뇌졸중', path: 'STR' },
                  { txt: '암', path: 'CDB' },
                  { txt: '급성심근경색', path: 'AMI' },
                  { txt: '실손의료비', path: 'MEI' },
                ].map(tab => (
                  <button
                    key={tab.path}
                    className={`tab_btn ${id === tab.path ? 'active' : ''}`}
                    onClick={() => goTo(`${REQUIRED_DOC_INFO}/${tab.path}`)}
                  >
                    {tab.txt}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className='guide mt_12 pdf_container'>
            {pdfUrl && (
              <PDFDocumentComponent
                file={pdfUrl}
                onLoadError={error => console.error('PDF 로드 에러:', error)}
                loading={<div className='pdf_loading_txt'>PDF를 불러오는 중입니다.</div>}
              >
                <PDFPageComponent
                  pageNumber={1}
                  scale={1.5} // 원하는 비율 (기본값은 1)
                  loading={<div className='pdf_loading_txt'>PDF를 불러오는 중입니다.</div>}
                />
              </PDFDocumentComponent>
            )}
          </div>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

export default RequiredDocInfoDetail;
