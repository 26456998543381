import DaumPostcodeEmbed, {
  Address,
  DaumPostcodePopupParams,
  useDaumPostcodePopup,
} from 'react-daum-postcode';
import useIsNativeWebView from '../hooks/useIsNativeWebView';
import { useState } from 'react';

const scriptUrl = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

interface PostcodeProps extends DaumPostcodePopupParams {
  callback: (data: string) => void;
  className?: string;
}

const Postcode = ({ callback, className }: PostcodeProps) => {
  const isNative = useIsNativeWebView();
  const [isOpen, setIsOpen] = useState(false);
  const open = useDaumPostcodePopup(scriptUrl);

  const handleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    if (isNative) {
      setIsOpen(false);
    }
    return callback(fullAddress);
  };

  const handleClick = () => {
    if (!isNative) {
      open({ onComplete: handleComplete });
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <button className={`btn white fs_15 fw_400 w_100 ${className || ''}`} onClick={handleClick}>
        주소 검색
      </button>
      {isNative && isOpen && (
        <dialog open={isOpen} className='fullscreen-dialog'>
          <DaumPostcodeEmbed scriptUrl={scriptUrl} onComplete={handleComplete} />
        </dialog>
      )}
    </>
  );
};

export default Postcode;
