import React from 'react';
import { LONG_TRAVEL_INSURANCE_STEP1 } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import AllInsuranceInfo from '../../components/longTravelInsurance/AllInsuranceInfo';

function LongTravelInsurance() {
  const { goTo } = useNavigateTo();

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='해외장기체류보험' />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container gray02'>
          <div className='guide pb_32'>
            <div className='mt_30'>
              <div className='title_wrap type02'>
                <i className='idcard_icon'></i>
                <h2 className='h2'>
                  <b>긴 여행도 이젠</b>
                  <br />
                  마음놓고 떠나세요
                </h2>
              </div>
              <div className='dis_flex justify_center'>
                <img src='/image/banner/introduce_banner_01.png' alt='' />
              </div>
            </div>

            <div className='introduce_box mt_32'>
              <div className='item'>
                <div className='tit'>상품안내</div>
                <div className='desc'>
                  · 해외에서 장기체류하는 동안 발생하는{' '}
                  <span className='col_blue'>상해 또는 질병사고를 보상하는 보험</span>이에요
                </div>
              </div>
              <div className='item'>
                <div className='tit'>가입목적</div>
                <div className='desc'>
                  · 어학연수, 유학, 해외출장, 교환교수, 주재원 등{' '}
                  <span className='col_blue'>3개월 이상 해외 체류자</span>에 한하여 가입할 수
                  있어요.
                </div>
              </div>
              <div className='item'>
                <div className='tit'>가입기간</div>
                <div className='desc'>
                  · <span className='col_blue'>3개월 이상 / 개월수 가입</span> (1년 이상 해외 체류
                  시 1년 가입 후 만기전 연장 가입)
                </div>
              </div>
            </div>
          </div>

          <AllInsuranceInfo />
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <div className='foot_btn'>
            <button className='btn blue' onClick={() => goTo(LONG_TRAVEL_INSURANCE_STEP1)}>
              보험료 조회하기
            </button>
          </div>
        </div>
        {/* <!-- // foot_container --> */}
      </div>
    </>
  );
}

export default LongTravelInsurance;
