import {
  SUBSCRIPTION_DAYS_LIST_DOMESTIC,
  SUBSCRIPTION_DAYS_LIST_LONG_TRAVEL,
  SUBSCRIPTION_DAYS_LIST_OVERSEAS,
} from '../constants/subscriptionDaysList';

type TravelType = 'LONG_TERM' | 'OVERSEAS' | 'DOMESTIC';

interface CheckTravelPeriodProps {
  startDate: string;
  endDate: string;
  travelType: TravelType;
}

export const checkTravelPeriod = ({
  startDate,
  endDate,
  travelType,
}: CheckTravelPeriodProps): boolean => {
  if (!startDate || !endDate) return false;
  const start = new Date(startDate);
  const end = new Date(endDate);

  // 일수 차이 계산 (밀리초 -> 일)
  const daysDifference = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));

  // 월 차이 계산
  const monthsDifference =
    (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

  switch (travelType) {
    case 'LONG_TERM':
      return monthsDifference >= 3; // 최소 3개월
    case 'OVERSEAS':
      return daysDifference >= 2; // 최소 2일
    case 'DOMESTIC':
      return daysDifference >= 2; // 최소 2일
    default:
      return false;
  }
};

interface TravelPeriodResult {
  isValid: boolean;
  message: string;
  recommendedPeriod?: SubscriptionPeriod;
  travelDays: number;
}

interface SubscriptionPeriod {
  key: string;
  value: string;
}

export const SUBSCRIPTION_PERIODS = {
  LONG_TERM: SUBSCRIPTION_DAYS_LIST_LONG_TRAVEL,
  OVERSEAS: SUBSCRIPTION_DAYS_LIST_OVERSEAS,
  DOMESTIC: SUBSCRIPTION_DAYS_LIST_DOMESTIC,
} as const;

const findRecommendedPeriod = (
  travelType: TravelType,
  startDate: Date,
  endDate: Date
): SubscriptionPeriod | undefined => {
  const periods = SUBSCRIPTION_PERIODS[travelType];
  const daysDifference =
    Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

  const getDays = (key: string): number => {
    if (key.includes('year')) return 365;
    if (key.includes('month')) return parseInt(key.replace('month', '')) * 31;
    return parseInt(key.replace('days', ''));
  };

  // 날짜에 따른 적절한 보험 기간 찾기
  // 28일 이하는 days 단위로 찾고, 그 이상은 month 단위로 찾도록 수정
  const availablePeriods = periods.filter(period => {
    const days = getDays(period.key);
    if (daysDifference <= 28) {
      return !period.key.includes('month') && !period.key.includes('year');
    }
    return period.key.includes('month') || period.key.includes('year');
  });

  const availableDays = availablePeriods.map(period => getDays(period.key));
  const closestDays = availableDays.reduce((prev, curr) =>
    Math.abs(curr - daysDifference) < Math.abs(prev - daysDifference) ? curr : prev
  );

  return periods.find(period => getDays(period.key) === closestDays);
};

export const checkTravelPeriodWithRecommendation = ({
  startDate,
  endDate,
  travelType,
}: CheckTravelPeriodProps): TravelPeriodResult => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // 시간 계산을 날짜로만 하도록 수정
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  // 날짜 차이 계산 수정 - 종료일도 포함하도록 +1
  const daysDifference = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;

  switch (travelType) {
    case 'LONG_TERM': {
      // 정확한 월 수 계산
      const yearDiff = end.getFullYear() - start.getFullYear();
      const monthDiff = end.getMonth() - start.getMonth();
      const dayDiff = end.getDate() - start.getDate();

      let exactMonths = yearDiff * 12 + monthDiff;
      if (dayDiff < 0) {
        exactMonths -= 1;
      }

      // 3개월 보험료로 단순화
      const isValid = exactMonths >= 3;
      const recommendedPeriod = isValid ? { key: 'month3', value: '3개월' } : undefined;

      return {
        isValid,
        message: isValid ? '' : '장기체류는 최소 3개월 이상이어야 합니다.',
        recommendedPeriod,
        travelDays: exactMonths,
      };
    }
    case 'OVERSEAS': {
      const isValid = daysDifference >= 2;
      const recommendedPeriod = isValid ? findRecommendedPeriod(travelType, start, end) : undefined;

      return {
        isValid,
        message: isValid ? '' : '해외여행은 최소 2일 이상이어야 합니다.',
        recommendedPeriod,
        travelDays: daysDifference,
      };
    }
    case 'DOMESTIC': {
      const isValid = daysDifference >= 2;
      const recommendedPeriod = isValid ? findRecommendedPeriod(travelType, start, end) : undefined;

      return {
        isValid,
        message: isValid ? '' : '국내여행은 최소 2일 이상이어야 합니다.',
        recommendedPeriod,
        travelDays: daysDifference,
      };
    }
    default:
      return {
        isValid: false,
        message: '올바르지 않은 여행 타입입니다.',
        travelDays: 0,
      };
  }
};
