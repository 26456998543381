import { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import useDomesticTravelFunnel from '../../hooks/useDomesticTravelFunnel';

function DomesticTravelInsuranceStep4() {
  const { goTo } = useNavigateTo();
  const { step4Values, setStep4Values, finalValues, submitDomesticTravel, clearForm } =
    useDomesticTravelFunnel();
  const [agreeValues, setAgreeValues] = useState<{ [key: string]: boolean | undefined }>(
    step4Values?.agreeValues || {
      check0: undefined,
      check1: undefined,
      check2: undefined,
      check3: undefined,
    }
  );
  const [successModalDesc, setSuccessModalDesc] = useState('');
  const isDisabled =
    Object.values(agreeValues).some(Boolean) === true ||
    Object.values(agreeValues).some(value => value === undefined);

  const onChangeIsAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    // 모든 항목을 "아니오"로 설정
    const newAgreeValues = Object.keys(agreeValues).reduce(
      (acc, key) => ({
        ...acc,
        [key]: false,
      }),
      {}
    );
    setAgreeValues(newAgreeValues);

    setStep4Values({ isAgree: checked, agreeValues: newAgreeValues });
  };

  const onChangeAgreeValues = (id: string, value: boolean) => {
    setAgreeValues({ ...agreeValues, [id]: value });
    setStep4Values({ isAgree: true, agreeValues: { ...agreeValues, [id]: value } });
  };

  const onCloseSuccessModal = () => {
    clearForm();
    goTo('/');
    setSuccessModalDesc('');
  };

  const onSubmit = () => {
    const req = { ...finalValues };
    if (req.travelPurpose !== '외국인 국내여행') {
      req.alienRegistrationNumber = undefined;
      req.alienRegistrationNumberYn = undefined;
    }
    submitDomesticTravel.mutate(req, {
      onSuccess: () => {
        setSuccessModalDesc('신청 완료');
      },
      onError: () => {
        alert('신청에 실패하였습니다. 관리자에게 문의해주세요.');
      },
    });
  };

  useEffect(() => {
    if (isDisabled) {
      setStep4Values({ isAgree: false, agreeValues });
    } else {
      setStep4Values({ isAgree: true, agreeValues });
    }
  }, [isDisabled]);

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='보험가입' />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='mt_28'>
              <div className='fs_18 fw_600'>계약 전 알릴 의무사항</div>
              <p className='mt_12 col_gray fs_16'>
                아래 항목에 해당하는 피보험자는 보험가입이 불가합니다. 해당 피보험자를 제외하고
                가입하시기 바랍니다.
              </p>
            </div>
            <div className='mt_18'>
              <input
                type='checkbox'
                id='checkAll'
                className='hide'
                onChange={onChangeIsAgree}
                checked={!isDisabled}
              />
              <label htmlFor='checkAll' className='btn gray02'>
                모두 아니오
              </label>
            </div>
            {INSURANCE_QUESTIONS.map(item => (
              <div className='mt_32' key={item.id}>
                <p className='fs_16 col_gray'>
                  {item.id + 1}. {item.question}
                </p>
                <div className='dis_flex justify_end gap20 mt_12'>
                  <div className='radio'>
                    <input
                      id={`rad${item.id}_1`}
                      name={`rad${item.id}`}
                      type='radio'
                      onChange={() => onChangeAgreeValues(`check${item.id}`, true)}
                      checked={agreeValues[`check${item.id}`] === true}
                    />
                    <label htmlFor={`rad${item.id}_1`}>예</label>
                  </div>
                  <div className='radio'>
                    <input
                      id={`rad${item.id}_2`}
                      name={`rad${item.id}`}
                      type='radio'
                      onChange={() => onChangeAgreeValues(`check${item.id}`, false)}
                      checked={agreeValues[`check${item.id}`] === false}
                    />
                    <label htmlFor={`rad${item.id}_2`}>아니오</label>
                  </div>
                </div>
              </div>
            ))}

            <div className='inner_gray_box square mt_36'>
              <div className='fs_13 col_gray'>
                * 사망담보를 제외한 실손의료비, 여행 중 배상책임, 여행 중 휴대품손해(분실제외), 여행
                중 중대사고 구조송환비용은 보험금을 지급할 다수의 보험계약(공제계약 포함)이 체결되어
                있는 경우, 약관에 따라 비례하여 보상합니다.
              </div>
              <div className='fs_13 col_gray mt_12'>
                * 출장 중 건설작업 등 현장작업 도중 발생한 사고는 보상되지 않습니다.
              </div>
              <div className='fs_13 col_gray mt_12'>
                * 보험가입 신청자는 피보험자에게 보험약관 및 가입증명서를 전달하여 계약사항을 반드시
                확인하도록 합니다.
              </div>
              <div className='fs_13 col_gray mt_12'>
                * 국민건강보험법 또는 의료급여법을 적용받지 못하는 외국인의 경우에는 본인이 실제로
                부담한 금액에서 공제금액을 뺀 금액의 40%를 보상한도내에서 보상합니다.
              </div>
            </div>

            <div className='col_red fs_13 mt_12'>
              ※ 사실과 답변이 다를 경우 계약유지 또는 보험금 지급에 영향을 미칠 수 있습니다.
            </div>
          </div>
        </div>

        <div className='foot_container'>
          <div className='foot_btn'>
            <button
              className={`btn blue ${isDisabled ? 'disabled' : ''}`}
              disabled={isDisabled || submitDomesticTravel.isLoading}
              onClick={onSubmit}
            >
              {submitDomesticTravel.isLoading ? '처리중' : '완료'}
            </button>
          </div>
        </div>
      </div>

      {/* <!-- alert_pop --> */}
      <SuccessModal
        title='보험가입요청이 정상적으로 완료되었습니다.'
        desc='담당자가 확인 후 연락 드리겠습니다'
        onClose={onCloseSuccessModal}
        sx={{ display: successModalDesc ? 'block' : 'none' }}
      />
    </>
  );
}

export default DomesticTravelInsuranceStep4;

const INSURANCE_QUESTIONS = [
  {
    id: 0,
    question: '최근 5년 이내 10대 질병으로 입원 또는 수술을 받은 사람이 명단에 포함되어 있습니까?',
  },
  {
    id: 1,
    question:
      '최근 3개월 이내 질병확정진단 / 질병의심소견 / 치료 / 입원 / 수술 / 투약 받은 사실이 있습니까? * 단순 질환 제외 (예 감기 등)',
  },
  {
    id: 2,
    question: '위험한 운동이나 전문적인 체육/운동대회 참가를 목적으로 여행하십니까?',
  },
  {
    id: 3,
    question: '현장 작업이 포함된 업무 출장 및 연수 목적으로 여행하십니까?',
  },
];

interface SuccessModalProps {
  title: string;
  desc: string;
  onClose: () => void;
  sx: any;
}

const SuccessModal = ({ title, desc, onClose, sx }: SuccessModalProps) => {
  return (
    <div className='alert_pop' id='alert02' style={sx}>
      <div className='pop_wrap'>
        <div className='pop_tit'>{title}</div>
        <div className='pop_body'>
          <div className='msg'>{desc}</div>
        </div>
        <div className='pop_btn'>
          <button className='btn black' onClick={onClose}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
