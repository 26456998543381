import { useEffect, useState } from 'react';
import { useCustomMutation, useCustomQuery } from '../api/apiHooks';
import endpointConfig from '../config/endpoint';
import { insuranceFeeKeys } from '../react-query/constants';

export type GuaranteInfoType = 'LONG_STAY' | 'OVERSEAS_TRAVEL' | 'DOMESTIC_TRAVEL';
export type LongStayPlan = 'W1' | 'W3' | 'W5' | 'W7' | 'W8' | 'W10';
export type OverseasPlan = 'A1' | 'A2' | 'A3' | 'A4' | 'A5';
export type DomesticPlan = '혼합형' | '알뜰형';
export type PlanType = LongStayPlan | OverseasPlan | DomesticPlan;
export type AccidentGubun = 0 | 1; // 0: 질병, 1: 상해

const useInsuranceFee = (
  infoType: GuaranteInfoType,
  plan: PlanType,
  ipBirthDate: string,
  gender: 1 | 0,
  day: string
) => {
  const query = useCustomQuery<{
    data: {
      fee: string;
    };
  }>(
    insuranceFeeKeys.overseasTravel(infoType, plan, ipBirthDate, gender, day),
    `${endpointConfig.insuranceFeeOverseasTravel}/${infoType}?plan=${plan}&ipBirthDate=${ipBirthDate}&gender=${gender ? 1 : 0}&days=${day}`,
    0
  );

  return query;
};

export default useInsuranceFee;
