import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';

const terms = [
  {
    content:
      '본인은 ‘신용 정보의 이용 및 보호에 관한 법률’ 제32조의 규정에 따라 보험금 지급심사의 목적으로 본인의 보험금 청구 서류 일체를 보험회사(손해보험사, 생명보험사), 유관기관(손해보험협회, 생명보험 협회) 및 손해 사정 업무를 위탁받은 회사로 제공하는 것에 동의합니다. 손해 사정 업무 위탁회사명의 확인을 원하실 경우 당해 보험회사로 문의하여 주시기 바랍니다.',
  },
  {
    content:
      '보험금 지급심사를 위해 필요시 추가로 관련 서류를 제출을 요청할 수 있으며 피보험자는 동일하나 수익자가 상이할 경우에는 각각의 수익자 명의의 통장사본 제출을 별도로 요구할 수 있습니다.',
  },
  {
    content:
      '고객님의 보험금 청구 서류는 보험금 지급심사를 위하여 보험계약의 효력 종료 시까지 또는 보험금 청구 건 및 반환청구권 소멸 시까지 보유합니다.',
  },
  {
    content:
      '이 서비스를 신청하는 타 보험회사에서는 고객님의 보험금 청구 서류를 수신(접수) 한 날이 보험금 청구일이 됩니다.',
  },
];

function SubmissionService() {
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='서비스 이용 동의' />

        {/* <!-- container --> */}
        <div className='container '>
          <div className='term submission'>
            <div className='inner'>
              {terms.map((section, index) => (
                <div key={index} className='section mt_14'>
                  <span className='fs_14 fw_400 line_break col_gray'>{index + 1}.</span>
                  <p className='fs_14 fw_400 line_break col_gray'>{section.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

export default SubmissionService;
