export const SUBSCRIPTION_DAYS_LIST_OVERSEAS = [
  { key: 'days2', value: '2일' },
  { key: 'days3', value: '3일' },
  { key: 'days4', value: '4일' },
  { key: 'days5', value: '5일' },
  { key: 'days6', value: '6일' },
  { key: 'days7', value: '7일' },
  { key: 'days10', value: '10일' },
  { key: 'days14', value: '14일' },
  { key: 'days17', value: '17일' },
  { key: 'days21', value: '21일' },
  { key: 'days24', value: '24일' },
  { key: 'days27', value: '27일' },
  { key: 'month1', value: '1개월' },
  { key: 'days46', value: '46일' },
  { key: 'month2', value: '2개월' },
  { key: 'month3', value: '3개월' },
  { key: 'year1', value: '1년' },
];

export const SUBSCRIPTION_DAYS_LIST_DOMESTIC = [
  { key: 'days2', value: '2일' },
  { key: 'days3', value: '3일' },
  { key: 'days4', value: '4일' },
  { key: 'days5', value: '5일' },
  { key: 'days6', value: '6일' },
  { key: 'days7', value: '7일' },
  { key: 'days10', value: '10일' },
  { key: 'days14', value: '14일' },
  { key: 'days17', value: '17일' },
  { key: 'days21', value: '21일' },
  { key: 'days24', value: '24일' },
  { key: 'days27', value: '27일' },
  { key: 'month1', value: '1개월' },
  { key: 'days46', value: '46일' },
  { key: 'month2', value: '2개월' },
];

export const SUBSCRIPTION_DAYS_LIST_LONG_TRAVEL = [
  { key: 'month3', value: '3개월' },
  { key: 'month4', value: '4개월' },
  { key: 'month5', value: '5개월' },
  { key: 'month6', value: '6개월' },
  { key: 'month7', value: '7개월' },
  { key: 'month8', value: '8개월' },
  { key: 'month9', value: '9개월' },
  { key: 'month10', value: '10개월' },
  { key: 'month12', value: '12개월' },
];
