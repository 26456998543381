import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { Link } from 'react-router-dom';
import { FIND_ID, FIND_PW, HOME, SIGNUP_CHECK } from '../../utils/routers';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AlertModal from '../../components/modal/AlertModal';
import useNavigateTo from '../../hooks/useNavigateTo';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { ls } from '../../utils/localStorage';
import { checkLogin } from '../../utils/auth';
import { useAppDispatch, useAppSelector } from '../../store';
import { setFindId } from '../../store/slices/authSlice';

const validationSchema = yup.object({
  id: yup.string().trim().required('아이디를 입력해주세요.'),
  pw: yup
    .string()
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      '영문, 숫자, 특수문자 조합 8~16자리로 입력해주세요.'
    )
    .required('비밀번호를 입력해주세요.'),
});

export type TInitialValues = {
  id: string; // 아이디
  pw: string; // 비밀번호
};

export type TLoginApiReq = {
  signId: string;
  password: string;
};

export type TLoginApiRes = {
  data: {
    id: string;
    signId: string;
    name: string;
    phone: string;
    accessToken: string;
    refreshToken: string;
  };
};

function Login() {
  const [isRealTimeValidationEnabled, setRealTimeValidationEnabled] = useState(false);
  const [isShowPw, setIsShowPw] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const auth = useAppSelector(store => store.auth);
  const dispatch = useAppDispatch();

  const { goTo } = useNavigateTo();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  const mutation = useCustomMutation<TLoginApiReq>(endpointConfig.login, 'post', 0, {
    onSuccess: (data: TDefaultResult & TLoginApiRes) => {
      if (data.status) {
        // accessToken, refreshToken, name 저장
        localStorage.setItem(ls.accessToken, data.data.accessToken);
        localStorage.setItem(ls.refreshToken, data.data.refreshToken);
        localStorage.setItem(ls.name, data.data.name);

        // 홈으로 이동
        goTo(HOME);
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      id: auth.findId ? auth.findId : '',
      pw: '',
    },
    validationSchema: validationSchema,
    validateOnChange: isRealTimeValidationEnabled,
    validateOnBlur: isRealTimeValidationEnabled,
    onSubmit: values => {
      mutation.mutate({ signId: values.id.trim(), password: values.pw.trim() });
    },
  });

  // 실시간 유효성 검사를 활성화한 후 validateOnChange와 validateOnBlur를 자동으로 업데이트
  useEffect(() => {
    if (formik.submitCount) {
      setRealTimeValidationEnabled(true);
    }
  }, [formik.submitCount]);

  // 이미 로그인 되어 있는지 확인하여 리다이렉트
  useEffect(() => {
    if (checkLogin()) {
      goTo(HOME);
    }
  }, [goTo]); // goTo 함수를 의존성 배열에 포함

  // findId 초기화
  useEffect(() => {
    return () => {
      dispatch(setFindId(''));
    };
  }, []);

  return (
    <>
      <div className='wrap login_bc'>
        {/* <!-- header --> */}
        <DetailHeader title='로그인' path={HOME} />

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='title_wrap mt_40'>
              <h2 className='h2'>
                회원 서비스 이용을 위해
                <br />
                <strong>로그인 해주세요.</strong>
              </h2>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div
                className={`inp_wrap mt_32 ${formik.errors.id && formik.touched.id ? 'state_red' : ''}`}
              >
                <label className='label'>아이디</label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='아이디를 입력해 주세요.'
                    name='id'
                    value={formik.values.id}
                    onChange={formik.handleChange}
                  />
                  <button
                    type='button'
                    className='remove_btn'
                    onClick={() => formik.setFieldValue('id', '')}
                    style={{
                      display: formik.values.id ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.id && formik.touched.id && (
                  <div className='state_txt'>{formik.errors.id}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_12 ${formik.errors.pw && formik.touched.pw ? 'state_red' : ''}`}
              >
                <label className='label'>비밀번호</label>
                <div className='inp'>
                  <input
                    type={isShowPw ? 'text' : 'password'}
                    placeholder='비밀번호를 입력해 주세요.'
                    name='pw'
                    value={formik.values.pw}
                    onChange={formik.handleChange}
                    maxLength={16}
                  />
                  <button
                    type='button'
                    className={`eye_icon ${isShowPw ? 'active' : ''}`}
                    onClick={() => setIsShowPw(prev => !prev)}
                    style={{
                      display: formik.values.pw ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.pw && formik.touched.pw && (
                  <div className='state_txt'>{formik.errors.pw}</div>
                )}
              </div>

              <div className='mt_24'>
                <button className='btn blue' type='submit'>
                  로그인
                </button>
              </div>
            </form>

            <div className='mt_24'>
              <div className='id_find_items'>
                <Link to={FIND_ID} className='item'>
                  아이디 찾기
                </Link>
                <Link to={FIND_PW} className='item'>
                  비밀번호 찾기
                </Link>
                <Link to={SIGNUP_CHECK} className='item'>
                  회원가입
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <p
            className='txt_center fs_10 col_gray2 pb_32 '
            style={{ backgroundColor: 'rgb(247, 247, 251)' }}
          >
            Copyright (C) 2024 SGIS all rights reserved.
          </p>
        </div>
        {/* <!-- // foot_container --> */}
      </div>

      {/* modal */}
      <AlertModal
        title='로그인 실패'
        desc={`아이디 혹은 비밀번호를 다시
입력해 주세요`}
        sx={{ display: errorMsg ? 'block' : 'none' }}
        onClose={() => setErrorMsg('')}
      />
    </>
  );
}

export default Login;
