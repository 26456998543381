import { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { CLAIM_ACCIDENT_STEP3 } from '../../utils/routers';
import useBottomSheet from '../../hooks/useBottomSheet';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import * as yup from 'yup';
import { FormikTouched, useFormik } from 'formik';
import { formatKoreanDate } from '../../utils/date';
import SignSheet from '../../components/groupAccidentInsurance/SignSheet';
import { Step2ValuesType } from '../../types/insurance/group-accident';
import SignInput from '../../components/groupAccidentInsurance/SignInput';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';
import { setFormikTouched, validFormik } from '../../utils/formik';
import { Link } from 'react-router-dom';

const createSchema = () => {
  return yup.object().shape({
    serviceStatus: yup
      .boolean()
      .isTrue('서비스 신청에 동의해주세요.')
      .required('서비스 신청에 동의해주세요.'),
    claimantSignaturePreview: yup.string().required('서명을 해주세요.'),
    invoiceTransfer: yup.boolean().required('청구서류 전송 방법을 선택해주세요.'),
    invoiceTransferCompany: yup.array().when('invoiceTransfer', {
      is: true,
      then: schema =>
        schema
          .required('청구서류 전송할 회사를 선택해주세요.')
          .test(
            'at-least-one-company',
            '보험사를 최소 1개 이상 입력해 주세요.',
            value => value && value.some(company => company.trim() !== '')
          ),
      otherwise: schema => schema.notRequired(),
    }),
  });
};

function ClaimAccientStep2() {
  const { goTo } = useNavigateTo();
  const { toggleBottomSheet, bottomSheetRef } = useBottomSheet();
  const { step2Values, setStep2Values, step1Values } = useGroupAccidentFunnel();
  const formik = useFormik({
    initialValues: { ...step2Values },
    validationSchema: createSchema(),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });
  const [isChecked, setIsChecked] = useState(step2Values?.serviceStatus ?? false);

  const [claimantSignaturePreview, setClaimantSignaturePreview] = useState<string | undefined>(
    step2Values?.claimantSignaturePreview
  );

  const onSaveSignature = async (url: string) => {
    setClaimantSignaturePreview(url);
    formik.setFieldValue('claimantSignaturePreview', url);
  };

  const onClickNext = async () => {
    setFormikTouched(formik);
    const isValid = await validFormik(formik);

    if (!isValid) return;

    goTo(CLAIM_ACCIDENT_STEP3);
  };

  useEffect(() => {
    setStep2Values({ ...formik.values, serviceStatus: isChecked } as Step2ValuesType);
  }, [formik.values]);

  const handleInvoiceTransferChange = (value: boolean) => {
    formik.setFieldValue('invoiceTransfer', value);
    if (value === false) {
      formik.setFieldValue('invoiceTransferCompany', []);
    }
  };

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='단체상해보험' hasTolltip={true} tooltipContent={<TooltipContent />} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container group_step2'>
        <div className='guide gray_bortop pb_28'>
          <div className='fs_18 fw_600 mt_28'>4. 청구서류 접수대행 서비스 신청서</div>
          <div className='col_gray fs_16 mt_12'>
            고객님께서 실손의료비보험을 2개 이상 다수 가입하신 경우, 각 회사마다 청구서류를 각 각
            제출하시는 번거로움을 덜어 드리고자 고객님의 서류를 다른 보험회사로 대신 전송해 드리고
            있습니다.
          </div>
          <div className='col_blue2 fw_600 mt_12'>
            단, 본 서비스는 실손의료비 특약만 해당되며 다른 특약은 해당되지 않습니다.
          </div>
          <div className='dis_flex mt_18 agree_sec'>
            <span className='col_blue fw_500'>청구서류 접수대행 서비스 이용 동의</span>
            <Link to='/terms/submission_survice' className='col_gray2'>
              보기
            </Link>
          </div>
          <div className='fs_18 fw_600 mt_26'>동 서비스를 신청하시겠습니까?</div>
          <div className='dis_flex gap12 mt_18'>
            <input
              type='radio'
              name='serviceStatus'
              id='agreeNo'
              className='hide'
              checked={formik.values.serviceStatus === false}
              onChange={e => {
                setIsChecked(e.target.checked);
                formik.setFieldValue('serviceStatus', false);
              }}
            />
            <label htmlFor='agreeNo' className='btn gray02'>
              아니오
            </label>
            <input
              type='radio'
              name='serviceStatus'
              id='agreeYes'
              className='hide'
              onChange={e => {
                setIsChecked(e.target.checked);
                formik.setFieldValue('serviceStatus', true);
              }}
              checked={formik.values.serviceStatus === true}
            />
            <label htmlFor='agreeYes' className='btn gray02'>
              예
            </label>
          </div>
          {formik.errors.serviceStatus && formik.touched.serviceStatus && (
            <div className='state_txt_error'>{String(formik.errors.serviceStatus)}</div>
          )}

          <div className='col_gray fs_16 mt_28'>
            고객님의 보험금 청구서류를 전송할 회사에 체크해 주세요.
          </div>

          <div className='dis_flex gap20 column mt_12'>
            <div className='radio w_full'>
              <input
                id='rad01'
                name='transCompany'
                type='radio'
                checked={formik.values.invoiceTransfer === false}
                onChange={e => {
                  handleInvoiceTransferChange(false);
                }}
              />
              <label htmlFor='rad01'>중복 가입된 모든 회사</label>
            </div>
            <div className='radio w_full'>
              <input
                id='rad02'
                name='transCompany'
                type='radio'
                checked={formik.values.invoiceTransfer === true}
                onChange={e => {
                  handleInvoiceTransferChange(true);
                }}
              />
              <label htmlFor='rad02'>서류를 보내고자 하는 회사</label>
            </div>
          </div>
          {formik.errors.invoiceTransfer && formik.touched.invoiceTransfer && (
            <div className='state_txt_error'>{String(formik.errors.invoiceTransfer)}</div>
          )}
          {formik.values.invoiceTransfer === true && (
            <div className='dis_flex mt_20 gap11 input_box'>
              {[0, 1, 2].map(index => (
                <div key={index}>
                  <input
                    type='text'
                    placeholder='보험사 입력'
                    name={`invoiceTransferCompany.${index}`}
                    value={formik.values.invoiceTransferCompany?.[index] || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              ))}
            </div>
          )}
          {formik.touched.invoiceTransferCompany &&
            formik.errors.invoiceTransferCompany &&
            formik.values.invoiceTransfer === true && (
              <div className='state_txt_error'>{String(formik.errors.invoiceTransferCompany)}</div>
            )}
        </div>
        <div className='guide gray_bortop'>
          <div className='fs_18 fw_600 mt_32'>신청일: {formatKoreanDate(new Date())}</div>
          <div className='inp_wrap mt_20'>
            <label className='label'>
              청구자 <span className='col_red'>*</span>
            </label>
            <div className='inp'>
              <input
                type='text'
                placeholder='이름을 입력해 주세요'
                value={step1Values?.ipName}
                readOnly
              />
            </div>
          </div>

          <SignInput
            signaturePreview={claimantSignaturePreview}
            toggleBottomSheet={toggleBottomSheet}
          />
          {formik.errors.claimantSignaturePreview && formik.touched.claimantSignaturePreview && (
            <div className='state_txt_error'>{String(formik.errors.claimantSignaturePreview)}</div>
          )}
        </div>
      </div>
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={onClickNext}>
            다음
          </button>
        </div>
      </div>

      <SignSheet
        showSignSheet={true}
        onSave={onSaveSignature}
        toggleBottomSheet={toggleBottomSheet}
        bottomSheetRef={bottomSheetRef}
      />
    </div>
  );
}

export default ClaimAccientStep2;
